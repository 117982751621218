<template>
  <div class="clip-content">
    <div class="clip-left">
      <SetSvgDom :data-source="itemArr" />
    </div>
    <div class="clip-right">
      <div class="clip-right-box1" v-if="totalTableData.length">
        <el-collapse class="mycollapsecss" v-model="activeNames">
          <el-collapse-item title=" " name="1">
            <template #title>
              <div class="collapse_title">统计数据</div>
            </template>
            <div>
              <x-table
                class="tablecss"
                :dataSource="totalTableData"
                :cloumns="totalCloumns"
                :border="false"
              >
                <template #name="{ scope }">
                  <span class="tableRowOneTitle">{{ scope.row.name }}</span>
                </template>

                <template #val="{ scope }">
                  <div style="white-space: pre-line" class="tableRowOneContent">
                    {{ scope.row.val }}
                  </div>
                </template>
              </x-table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="clip-right-box1" v-if="banTableData.length">
        <el-collapse class="mycollapsecss" v-model="activeNames1">
          <el-collapse-item title=" " name="1">
            <template #title>
              <div class="ban-header">
                <div class="collapse_title">板料数据</div>
                <div
                  style="margin-left: 10px"
                  v-if="banAllTableData.length > 1"
                >
                  <i
                    style="font-size: 16px"
                    class="el-icon-arrow-left"
                    @click.stop="lastClick"
                  ></i
                  ><span style="font-size: 16px"
                    >{{ banCount }}/{{ banAllTableData.length }}</span
                  ><i
                    style="font-size: 16px"
                    class="el-icon-arrow-right"
                    @click.stop="nextClick"
                  ></i>
                </div>
              </div>
            </template>
            <div>
              <x-table
                class="tablecss"
                :dataSource="banTableData"
                :cloumns="banCloumns"
                :border="false"
              >
                <template #name="{ scope }">
                  <span class="tableRowOneTitle">{{ scope.row.name }}</span>
                </template>
                <template #val="{ scope }">
                  <div class="tableRowOneContent">
                    {{ scope.row.val }}
                  </div>
                </template>
              </x-table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div
        v-if="noFitPanelsTableData.length"
        class="clip-right-box1 noFitPanels1"
      >
        <el-collapse class="noFitPanels2" v-model="activeNames2">
          <el-collapse-item title=" " name="1">
            <template #title>
              <div class="collapse_title">无法放入的部件</div>
            </template>
            <div>
              <x-table
                :dataSource="noFitPanelsTableData"
                :cloumns="noFitPanelsCloumns"
                :border="false"
              >
                <template #size="{ scope }">
                  <span>{{ scope.row.height }}x{{ scope.row.width }}</span>
                </template>
              </x-table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="clip-right-box1" v-if="cutsData.length">
        <el-collapse class="mycollapsecss" v-model="activeNames3">
          <el-collapse-item title=" " name="1">
            <template #title>
              <div class="collapse_title">裁切次数</div>
            </template>
            <div
              v-for="(item, index) in this.cutData.solution.mosaics"
              :key="index"
              style="margin-bottom: 10px"
            >
              <x-table
                :dataSource="item.cuts"
                :cloumns="cutsCloumns"
                :border="false"
                :class="index > 0 ? 'tablecss' : ''"
              >
                <template #index="{ scope }">{{
                  parseInt(scope.row.index) + 1
                }}</template>
                <template #size="{ scope }">
                  <span
                    >{{ scope.row.originalHeight }}x{{
                      scope.row.originalWidth
                    }}</span
                  >
                </template>
                <template #position="{ scope }">
                  <span
                    >{{ scope.row.horizontal ? "x=" : "y="
                    }}{{ scope.row.cutCoord }}</span
                  >
                </template>
                <template #result="{ scope }">
                  <span>{{
                    scope.row.cutCoord + "x" + scope.row.originalHeight
                  }}</span>
                </template>
              </x-table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import SetSvgDom from "@/components/SetSvgDom";
import { handlerBanData, areaUnitHandle } from "@/tools/utils";

export default {
  props: {
    dataSource: {
      type: [Object, String],
      default: () => {},
    },
  },
  components: {
    SetSvgDom,
  },
  computed: {
    configurationData() {
      return this.$store.getters.configurationData;
    },
  },
  watch: {
    activeNames(newV) {
      console.log(this.$refs);
    },
    dataSource: {
      handler(nVal, oVal) {
        if (nVal) {
          this.itemArr = nVal.solution;
          this.cutData = nVal;
          this.beginRect();
        }
      },
      immediate: true,
      deep: true,
    },
    configurationData: {
      handler(nVal, oVal) {
        if (this.cutData.solution && this.cutData.solution.mosaics.length) {
          this.banCount = 1;
          this.getBanData(this.cutData.solution.mosaics);
          this.getNoFitPanels();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      activeNames: ["1"],
      activeNames1: ["1"],
      activeNames2: ["1"],
      activeNames3: ["1"],
      itemArr: {},
      cloumns1: [
        { field: "name", title: "" },
        { field: "val", title: "" },
      ],
      totalCloumns: [
        { field: "name", title: "", slot: "name" },
        { field: "val", title: "", slot: "val" },
      ],
      cutsData: [],
      cutsCloumns: [
        { field: "index", title: "#", width: 45, slot: "index" },
        { field: "size", title: "部件规格", slot: "size", width: 100 },
        { field: "position", title: "裁切位置", slot: "position" },
        { field: "result", title: "Result", slot: "result", width: 100 },
      ],
      noFitPanelsCloumns: [
        { field: "size", title: "部件规格", slot: "size", width: 100 },
        { field: "label", title: "部件名称" },
        { field: "count", title: "数量" },
      ],
      noFitPanelsTableData: [],
      totalTableData: [],
      banTableData: [],
      banAllTableData: [],
      banCloumns: [
        { field: "name", title: "", slot: "name" },
        { field: "val", title: "", slot: "val" },
      ],
      banCount: 1,
      cutData: {},
    };
  },
  mounted() {
    this.$on("play", () => {
      this.beginComputed();
    });
    this.$EventBus.$on("clearSvgDom", () => {
      console.log(222);
      this.noFitPanelsTableData = [];
      this.totalTableData = [];
      this.banTableData = [];
      this.banAllTableData = [];
      this.cutsData = [];
    });
  },
  methods: {
    beginRect() {
      this.$nextTick(() => {
        this.beginComputed();
        this.getCuts();
        this.getNoFitPanels();
      });
    },
    //开始计算
    beginComputed() {
      if (!this.cutData.solution || !this.cutData.solution.mosaics.length) {
        return;
      }
      this.$store.dispatch("cutData", this.cutData.solution);

      this.getTotalData();

      this.getBanData(this.cutData.solution.mosaics);
    },

    getTotalData() {
      const data = this.cutData.solution;
      if (!this.cutData.solution) {
        return;
      }
      var usedStockPanelsStr = [];
      this.cutData.solution.usedStockPanels.forEach((res) => {
        usedStockPanelsStr.push(
          res.height +
            "x" +
            res.width +
            " x" +
            res.count +
            (res.label ? " \\ " + res.label : "")
        );
      });
      this.totalTableData = [
        { name: "用掉板料", val: usedStockPanelsStr.join("\n") },
        {
          name: "使用总面积",
          val:
            data.totalUsedArea +
            areaUnitHandle(true) +
            " \\ " +
            (data.totalUsedAreaRatio * 100).toFixed(0) +
            " %",
        },
        {
          name: "剩余总面积",
          val:
            data.totalWastedArea +
            areaUnitHandle(true) +
            " \\ " +
            (100 - (data.totalUsedAreaRatio * 100).toFixed(0)) +
            " %",
        },
        { name: "裁切总数", val: data.totalNbrCuts },
        { name: "裁切总长", val: data.totalCutLength + areaUnitHandle() },
        {
          name: "锯片厚度",
          val: this.configurationData.cutThickness + areaUnitHandle(),
        },
        {
          name: "优化优先级",
          val: !this.configurationData.optimizationPriority
            ? "最少余料面积"
            : this.configurationData.optimizationPriority === 1
            ? "最小切割次数"
            : "优先使用最小尺寸板料",
        },
      ];
    },
    getBanData(data) {
      console.log(data);
      var arrStr = [];
      data.forEach((element) => {
        arrStr.push(JSON.parse(handlerBanData(element)));
      });
      this.banAllTableData = arrStr;

      this.banTableData = this.banAllTableData[this.banCount - 1];
    },
    lastClick() {
      if (this.banCount == 1) {
        return;
      }
      this.banCount -= 1;
      this.banTableData = this.banAllTableData[this.banCount - 1];
    },
    nextClick() {
      if (this.banCount >= this.banAllTableData.length) {
        return;
      }
      this.banCount += 1;
      this.banTableData = this.banAllTableData[this.banCount - 1];
    },
    //去重
    removeRepeatStr(str) {
      console.log(str);
      var newArray = [];
      // if (
      //   this.configurationData.stackEqualMosaicLayout === 0 ||
      //   this.configurationData.stackEqualMosaicLayout === 1
      // ) {
      // var newStr = [];
      // var len = str.length;
      // const countMap = new Map();
      // for (var i = 0; i < len; i++) {
      //   if (newStr.indexOf(str[i]) > -1) {
      //     var count = countMap.get(str[i]);
      //     countMap.set(str[i], count + 1);
      //   }
      //   if (newStr.indexOf(str[i]) === -1) {
      //     newStr.push(str[i]);
      //     countMap.set(str[i], 1);
      //   }
      // }
      //
      // for (var i = 0; i < newStr.length; i++) {
      //   const item = JSON.parse(newStr[i]);
      //   item[2].val = countMap.get(JSON.stringify(item));
      //   newArray.push(item);
      // }
      // } else if (this.configurationData.stackEqualMosaicLayout === 2) {
      for (var i = 0; i < str.length; i++) {
        var item = str[i];
        item[2].val = item.count;
        newArray.push(item);
      }
      // }
      console.log(newArray);
      return newArray;
    },
    getCuts() {
      var tableData = [];
      if (!this.cutData.solution || !this.cutData.solution.mosaics.length) {
        return;
      }
      this.cutData.solution.mosaics.forEach((res) => {
        for (let index = 0; index < res.cuts.length; index++) {
          const element = res.cuts[index];
          tableData.push(element);
        }
      });
      // console.log(tableData)
      this.cutsData = tableData;
    },
    getNoFitPanels() {
      if (!this.cutData.solution) {
        return;
      }
      if (!this.configurationData.isTileLabelVisible) {
        this.noFitPanelsCloumns = [
          { field: "size", title: "部件规格", slot: "size", width: 100 },
          { field: "count", title: "数量" },
        ];
      } else {
        this.noFitPanelsCloumns = [
          { field: "size", title: "部件规格", slot: "size", width: 100 },
          { field: "label", title: "部件名称" },
          { field: "count", title: "数量" },
        ];
      }
      this.noFitPanelsTableData = this.cutData.solution.noFitPanels;
    },
  },
};
</script>

<style lang="scss" scoped>
.clip-content {
  width: 100%;
  height: 100%;
  display: block;

  .clip-left {
    width: calc(65%);
    height: 100%;
    float: left;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
  }
  .clip-right {
    width: 35%;
    height: 100%;
    float: left;
    display: block;
    padding: 5px;
    box-sizing: border-box;
    overflow: auto;
  }
}
.clip-right-box1 {
  width: 100%;
  border-radius: 6px;
  border-left: 0.5px solid $them-color;
  border-right: 0.5px solid $them-color;
  border-bottom: 0.5px solid $them-color;
  overflow: hidden;
  &:not(:first-child) {
    margin-top: 20px;
  }
}
.mycollapsecss {
  border: none !important;

  /deep/ .el-collapse {
    border: none !important;
  }
  /deep/ .el-collapse-item__header {
    background: $them-color !important;
    height: 28px !important;
    color: #fff;
    // border-top-right-radius: 6px;
    // border-top-left-radius: 6px;
  }
}
.tablecss {
  /deep/ .el-table__header-wrapper,
  .el-table__footer-wrapper {
    display: none;
  }
}
.noFitPanels1 {
  border-left: 0.5px solid #e57373;
  border-right: 0.5px solid #e57373;
  border-bottom: 0.5px solid #e57373;
  /deep/ .el-collapse-item__header {
    background: #e57373 !important;
    color: #fff;
  }
  /deep/ .el-collapse {
  }
}
.ban-header {
  display: flex;
}
.collapse_title {
  margin-left: 5px;
  font-size: 16px;
}
.tableRowOneTitle {
  font-size: 13px;
  font-weight: 700;
  color: rgb(51, 51, 51);
}
.tableRowOneContent {
  font-size: 13px;
  color: rgb(51, 51, 51);
}
</style>
<style>
.el-collapse-item__arrow {
  font-weight: 900 !important;
  font-size: 18px;
}
</style>
