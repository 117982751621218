<template>
  <div class="content-clip">
    <div :ref="refName" class="clip-box">
      <div :id="refName" class="clip-content"></div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

export default {
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
    refName: {
      type: String,
      default: "clipLeft",
    },
    //使用最大或者最小的宽度作为比例
    useWidthScale: {
      type: Boolean,
      default: false,
    },
    itemWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    configurationData() {
      return this.$store.getters.configurationData;
    },
  },
  watch: {
    dataSource: {
      handler(newVal, oldVal) {
        this.itemObject = newVal;
        this.svgSetup();
      },
      immediate: true,
      deep: true,
    },
    "configurationData.isTileLabelVisible": {
      handler(nval, oval) {
        this.isTileLabelVisible = nval;
        this.svgSetup();
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      itemObject: {},
      isTileLabelVisible: false,
      isStop: false,
    };
  },
  mounted() {
    console.log(this.itemObject);
    window.onresize = () => {
      return (() => {
        this.svgSetup();
      })();
    };
    this.$on("play", (val) => {
      this.isStop = !val;
    });
    this.$EventBus.$on("clearSvgDom", () => {
      this.$store.dispatch("setResult", "");
      this.$nextTick(() => {
        if (document.getElementById(this.refName)) {
          document.getElementById(this.refName).innerHTML = "";
        }
      });
    });
    this.$EventBus.$on("dragEvent", () => {
      this.svgSetup();
    });
    this.$EventBus.$on("jpegHandler", () => {
      this.$nextTick(async () => {
        const canvas = await html2canvas(this.$refs.clipLeft, {
          allowTaint: true, // 允许渲染跨域图片
          scale: window.devicePixelRatio * 1, // 增加清晰度
          x: 0,
          // width: element.clientWidth, // Set the width to match the element's width
          // height: element.clientHeight,
          useCORS: true, // 允许跨域
          onrendered: function (canvas) {
            document.body.appendChild(canvas);
          },
        });
        const link = document.createElement("a");
        const event = new MouseEvent("click");
        link.download = this.$store.getters.projectName + ".jpeg";
        link.href = canvas.toDataURL("image/jpeg", 1.0);
        link.dispatchEvent(event);
      });
    });
  },
  methods: {
    getMaxOriginalWidth() {
      var arr = [];
      this.itemObject.usedStockPanels.forEach((res) => {
        arr.push(res.width);
      });
      return Math.max.apply(null, arr);
    },
    getMinOriginalWidth() {
      var arr = [];
      this.itemObject.usedStockPanels.forEach((res) => {
        arr.push(res.width);
      });
      return Math.min.apply(null, arr);
    },
    svgSetup() {
      if (!this.itemObject) {
        return;
      }
      if (
        this.itemObject &&
        this.itemObject.mosaics &&
        this.itemObject.mosaics.length === 0
      ) {
        return;
      }
      //   先清空dom里面的元素再绘制
      var myDiv = document.getElementById(this.refName);
      while (myDiv && myDiv.children.length > 0) {
        for (let index = 0; index < myDiv.children.length; index++) {
          const element = myDiv.children[index];
          myDiv.removeChild(element);
        }
      }
      if (!this.$refs[this.refName]) {
        return;
      }
      let clientW = this.$refs[this.refName].clientWidth - 60;
      if (!this.itemObject.usedStockPanels) {
        return;
      }
      //使用最大值进行比例计算
      var scale = clientW / this.getMaxOriginalWidth();
      if (this.useWidthScale) {
        scale = clientW / this.itemWidth;
      }
      var clientH = 0;
      this.itemObject.mosaics.forEach((res) => {
        clientH += res.tiles[0].height * scale;
      });
      const svgBox = d3
        .select("#" + this.refName)
        .append("svg")
        .attr("width", clientW + 50)
        .attr("height", clientH + 60 * this.itemObject.mosaics.length);

      this.setDefs(svgBox);

      const group = svgBox.append("g");
      var totalY = 20;
      this.itemObject.mosaics.forEach((res, index) => {
        res.width = res.cuts[0].originalWidth;
        res.height = res.cuts[0].originalHeight;
        var totalW = scale * res.width;
        var totalH = scale * res.height;
        if (index > 0) {
          totalY += 60;
        }

        group
          .append("rect")
          .attr("x", 2)
          .attr("y", totalY)
          .attr("width", totalW)
          .attr("height", totalH)
          .attr("vector-effect", "non-scaling-stroke")
          .style("stroke", "black")
          .style("stroke-width", 2)
          .style("filter", "url(#drop-shadow)")
          .attr("fill", "white");

        var fontSize = totalH / 35;
        if (fontSize > 15) {
          fontSize = 15;
        }
        if (fontSize < 9) {
          fontSize = 9;
        }
        this.rectLine(svgBox, totalW, totalH, totalY, res, fontSize);
        var mosaic = this.itemObject.mosaics[index];
        this.rectDraw(mosaic, group, scale, totalH, totalY, fontSize);
        totalY += totalH;
      });
    },
    setDefs(svg) {
      var defs = svg.append("defs");

      var linearGradient = defs
        .append("linearGradient")
        .attr("id", "gradient5")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "100%")
        .attr("spreadMethod", "pad");
      linearGradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "#6E3A68")
        .attr("stop-opacity", "0.1");

      linearGradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "#6E3A68")
        .attr("stop-opacity", "0.25");

      var filter = defs
        .append("filter")
        .attr("id", "drop-shadow")
        .attr("height", "120%");
      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", "2")
        .attr("result", "blur");
      filter
        .append("feOffset")
        .attr("in", "blur")
        .attr("dx", "2")
        .attr("dy", "2")
        .attr("result", "offsetBlur");
      var feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");
    },

    rectDraw(mosaic, svg, scale, totalH, totalY, fontSize) {
      for (let index = 0; index < mosaic.tiles.length; index++) {
        //停止计算、绘制
        if (this.isStop) {
          return;
        }
        let item = mosaic.tiles[index];
        var fillColor = "url(#gradient5)";
        var strokeColor = "black";
        var textColor = "#000000";

        if (item.hasChildren) {
          continue;
        }

        const x = item.x * scale + 2;
        const y = totalH - item.y * scale - item.height * scale + totalY;
        const w = item.width * scale;
        const h = item.height * scale;

        if (!item.hasChildren && !item.final) {
          fillColor = "#f5f5f5";
          strokeColor = "rgb(187, 187, 187)";
          textColor = "#90A4AD";
          svg
            .append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", w)
            .attr("height", h)
            .attr("vector-effect", "non-scaling-stroke")
            .style("fill", fillColor)
            .style("stroke", strokeColor)
            .style("stroke-width", 0.5);
        }
        //小板料
        if (!item.hasChildren && item.final) {
          svg
            .append("rect")
            .attr("x", x - 0.5)
            .attr("y", y + 0.5)
            .attr("width", w - 0.5)
            .attr("height", h - 1)
            .attr("vector-effect", "non-scaling-stroke")
            .style("fill", fillColor)
            .style("stroke", strokeColor)
            .style("stroke-width", 1);
        }

        // 宽度文本
        svg
          .append("text")
          .attr("x", x + w / 2)
          .attr("y", y + fontSize)
          .attr("text-anchor", "middle")
          // .attr("font-family", "sans-serif")
          .attr("fill", textColor)
          .style("pointer-events", "none")
          .style("font-size", fontSize)
          .style("user-select", "none")
          .text(item.width);

        // 高度文本
        const textX = x + fontSize;
        const textY = y + h / 2;
        svg
          .append("text")
          .attr("x", textX)
          .attr("y", textY)
          .attr("text-anchor", "middle")
          // .attr("font-family", "sans-serif")
          .attr("fill", textColor)
          .attr("transform", `rotate(270,${textX},${textY})`)
          .style("pointer-events", "none")
          .style("font-size", fontSize)
          .style("user-select", "none")
          .text(item.height);

        // 名称文本
        if (this.isTileLabelVisible) {
          const nameX = x + w / 2;
          const nameY = y + h / 2 + 4;
          svg
            .append("text")
            .attr("x", nameX)
            .attr("y", nameY)
            .attr("text-anchor", "middle")
            // .attr("font-family", "sans-serif")
            .attr("font-size", fontSize)
            .attr("fill", "#727272")
            .style("pointer-events", "none")
            .style("user-select", "none")
            .text(item.label);
        }
      }
      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", svg.attr("width"))
        .attr("height", svg.attr("height"))
        .attr("fill", "none")
        .attr("stroke", "black")
        .attr("stroke-width", 1.2);
    },
    //绘制标记线
    rectLine(svg, totalW, totalH, totalY, res, fontSize) {
      svg
        .append("text")
        .attr("x", totalW + 25)
        .attr("y", totalY + totalH / 2 + 10)
        .attr("text-anchor", "middle")
        .attr("background", "black")
        .attr("font-size", fontSize)
        .attr("fill", "#d9534f")
        .attr(
          "transform",
          `rotate(270,${totalW + 25},${totalY + totalH / 2 + 10})`
        )
        .style("pointer-events", "none")
        .style("user-select", "none")
        .text(res.height);

      svg
        .append("text")
        .attr("x", totalW / 2)
        .attr("y", totalY + totalH + 25)
        .attr("text-anchor", "middle")
        .attr("background", "black")
        .attr("font-size", fontSize)
        .attr("fill", "#d9534f")
        .style("pointer-events", "none")
        .style("user-select", "none")
        .text(res.width);

      //高度标记
      svg
        .append("line")
        .attr("x1", totalW + 9)
        .attr("y1", totalY)
        .attr("x2", totalW + 15)
        .attr("y2", totalY)
        .attr("stroke", "red")
        .attr("stroke-width", 1);

      svg
        .append("line")
        .attr("x1", totalW + 9)
        .attr("y1", totalY + totalH)
        .attr("x2", totalW + 15)
        .attr("y2", totalY + totalH)
        .attr("stroke", "red")
        .attr("stroke-width", 1);

      svg
        .append("line")
        .attr("x1", totalW + 12)
        .attr("y1", totalY)
        .attr("x2", totalW + 12)
        .attr("y2", totalY + totalH)
        .attr("stroke", "red")
        .attr("stroke-width", 1);

      // 宽度标记
      svg
        .append("line")
        .attr("x1", 0)
        .attr("y1", totalY + totalH + 9)
        .attr("x2", 0)
        .attr("y2", totalY + totalH + 15)
        .attr("stroke", "red")
        .attr("stroke-width", 1);

      svg
        .append("line")
        .attr("x1", totalW)
        .attr("y1", totalY + totalH + 9)
        .attr("x2", totalW)
        .attr("y2", totalY + totalH + 15)
        .attr("stroke", "red")
        .attr("stroke-width", 1);

      svg
        .append("line")
        .attr("x1", 0)
        .attr("y1", totalY + totalH + 12)
        .attr("x2", totalW)
        .attr("y2", totalY + totalH + 12)
        .attr("stroke", "red")
        .attr("stroke-width", 1);

      //数量
      const count = res.count ? res.count : 1;
      svg
        .append("text")
        .attr("x", totalW + 30)
        .attr("y", totalY + totalH)
        .attr("text-anchor", "middle")
        .attr("background", "black")
        .attr("font-size", "16px")
        .attr("fill", "#343434")
        .style("pointer-events", "none")
        .style("user-select", "none")
        .text("x" + count);

      if (this.isTileLabelVisible) {
        svg
          .append("text")
          .attr("x", totalW)
          .attr("y", totalY - 5)
          .attr("text-anchor", "middle")
          .attr("background", "black")
          .attr("font-size", fontSize)
          .attr("fill", "#343434")
          .style("pointer-events", "none")
          .style("user-select", "none")
          .attr("font-weight", 500)
          .text(res.stockLabel);
      }
    },
  },
};
</script>

<style scoped>
.content-clip {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.clip-content {
  display: flex;
  flex-direction: column;
}
.clip-box {
  width: calc(100% - 20px);
  padding: 5px;
}
</style>
