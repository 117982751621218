<template>
  <div>
    <el-table
      ref="multipleTable"
      :data="dataSource"
      :border="border"
      stripe
      :style="{ width: tableW }"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      @row-click="handleCurrentRowClick"
      style="width: 100%"
      :header-cell-style="{
        background: '#F3F3F3',
        color: '#333333',
      }"
      @cell-click="totalCountcellClick"
      :cell-class-name="
        ({ row, column, rowIndex, columnIndex }) => (
          (row.index = rowIndex), (column.index = columnIndex)
        )
      "
    >
      <el-table-column
        v-if="isSelection"
        align="center"
        type="selection"
        width="55"
      >
      </el-table-column>

      <template v-for="(item, index) in inCloumns">
        <el-table-column
          :key="index"
          :prop="item.field"
          :fixed="item.fixed"
          :label="item.title"
          :min-width="item.width"
          :align="item.align ? item.align : 'left'"
          :show-overflow-tooltip="item.tooltip"
          v-if="!item.isHiden"
        >
          <template slot="header" slot-scope="scope">
            <el-dropdown
              v-if="item.showHeader"
              type="primary"
              trigger="click"
              @command="controlHandler"
            >
              <img class="menu-icon" src="@/assets/images/menu.png" alt="" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add">新增一行</el-dropdown-item>
                <!-- <el-dropdown-item>汇入清单</el-dropdown-item>
                <el-dropdown-item>储存清单</el-dropdown-item> -->
                <!-- <el-dropdown-item>开启/关闭所有设定</el-dropdown-item> -->
                <el-dropdown-item command="clear">清空</el-dropdown-item>
                <el-dropdown-item command="csv_export"
                  >输出为CSV</el-dropdown-item
                >
                <el-dropdown-item command="csv_import"
                  >从CSV文件导入</el-dropdown-item
                >
                <!-- <el-dropdown-item>以板料长宽替换部件名称</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <span v-if="item.title">{{ item.title }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="item.slot">
              <slot :name="item.slot" :scope="scope"></slot>
            </span>
            <span v-else> {{ scope.row[item.field] }} </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      v-if="total > 0"
      :small="isSmall"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="inCurrentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :layout="
        isSmall
          ? 'total, prev, pager, next,jumper'
          : 'total, sizes, prev, pager, next, jumper'
      "
      :total="total"
      style="margin-top: 10px"
    >
    </el-pagination>
  </div>
</template>

<script>
// import { datesFormat } from "@/utils/tools";
export default {
  computed: {
    // datesToStr() {
    //   return (val) => {
    //     if (val) {
    //       return datesFormat(val, "YYYY-MM-DD hh:mm");
    //     } else {
    //       return "";
    //     }
    //   };
    // },
  },
  props: {
    cloumns: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    tableW: {
      type: [Number, String],
      default: "100%",
    },
    isSelection: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    border: {
      type: Boolean,
      default: true,
    },
    toggleRowSelection: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dataSource: {
      handler(newVal, oldVal) {
        this.dataSource = newVal;
        if (this.inCurrentPage > 1 && this.dataSource.length == 0) {
          this.handleCurrentChange(this.inCurrentPage - 1);
        }
      },
      immediate: true,
      deep: true,
    },
    loading: {
      handler(newVal, oldVal) {
        this.loading = newVal;
      },
      immediate: true,
      deep: true,
    },
    currentPage: {
      handler(newVal, oldVal) {
        this.inCurrentPage = newVal;
      },
      immediate: true,
    },
    total: {
      handler(newVal, oldVal) {
        this.total = newVal;
      },
      immediate: true,
      deep: true,
    },
    toggleRowSelection: {
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          newVal.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        }
      },
      immediate: true,
      deep: true,
    },
    cloumns: {
      handler(nVal, oVal) {
        // console.log(nVal);
        this.inCloumns = nVal;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      inCloumns: [],
      inCurrentPage: 1,
    };
  },
  mounted() {
    this.$refs.multipleTable.$ready = true;
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.inCurrentPage = val;
      this.$emit("currentChange", val);
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    handleCurrentRowClick(row, column, event) {
      this.$emit("row-click", row, column, event);
    },
    totalCountcellClick(row, column) {
      this.$emit("cell-click", row, column);
    },
    controlHandler(a) {
      this.$emit("controlHandler", a);
    },
  },
};
</script>

<style scoped>
.menu-icon {
  width: 20px;
}
</style>
