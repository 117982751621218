<template>
  <div class="app-main" v-loading="loading">
    <Pdf :cut-vals="cutSvgData.solution"></Pdf>
    <NavBar></NavBar>
    <div class="box" ref="box">
      <div
        class="left"
        v-loading="leftLoading"
        :element-loading-text="resultTitle"
      >
        <div class="left-content">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="Consistency" name="1">
              <template #title>
                <div class="title">
                  <!-- <img src="@/assets/images/com.png" alt="" /> -->

                  <i class="el-icon-menu icon"></i>
                  部件尺寸
                </div>
              </template>
              <div>
                <x-table
                  :dataSource="tableData"
                  :cloumns="cloumns"
                  @cell-click="totalCountcellClick"
                  @controlHandler="controlHandler"
                  tableW="99.99%"
                  class="mytablecss"
                >
                  <template #width="{ scope }">
                    <div v-if="tableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.width"
                        @blur="widthblur(scope.row)"
                        @keydown="
                          tableRowInputKeyup($event, scope.$index, 'title0')
                        "
                        @input="isAddRow(scope)"
                        type="number"
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.width }}</div>
                  </template>
                  <template #height="{ scope }">
                    <div v-if="tableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.height"
                        @blur="widthblur(scope.row)"
                        @keydown="
                          tableRowInputKeyup($event, scope.$index, 'title1')
                        "
                        @input="isAddRow(scope)"
                        type="number"
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.height }}</div>
                  </template>
                  <template #count="{ scope }">
                    <div v-if="tableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.count"
                        @blur="widthblur(scope.row)"
                        @keydown="
                          tableRowInputKeyup($event, scope.$index, 'title2')
                        "
                        @input="
                          (val) => (scope.row.count = val.replace(/\D/g, ''))
                        "
                        type="number"
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.count }}</div>
                  </template>
                  <template #material="{ scope }">
                    <div v-if="selectTableCellClickRowIndexHandle(scope)">
                      <select
                        name=""
                        id=""
                        @blur="selWidthblur(scope.row)"
                        v-model="scope.row.material"
                        @change="selectChange($event, scope.row)"
                        style="width: 100%"
                        :ref="'ban' + scope.row.id"
                      >
                        <option
                          v-for="(item, index) in materialSelects"
                          :value="item.value"
                          :key="index"
                        >
                          {{ item.key }}
                        </option>
                      </select>
                    </div>
                    <div v-else>{{ scope.row.material }}</div>
                  </template>
                  <template #label="{ scope }">
                    <div v-if="tableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.label"
                        @blur="widthblur(scope.row)"
                        @keydown="
                          tableRowInputKeyup($event, scope.$index, 'title4')
                        "
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.label }}</div>
                  </template>
                  <template #control="{ scope }">
                    <div class="contro_orientation">
                      <el-button
                        type="text"
                        size="mini"
                        class="orientation"
                        @click="orientationClick(scope.row)"
                        v-if="
                          configurationData.considerOrientation &&
                          isShowControls(scope.row)
                        "
                      >
                        <!-- 木纹依照宽边走 -->
                        <span
                          v-if="scope.row.orientation == 1"
                          class="glyphicon glyphicon-resize-horizontal orientation-width"
                        ></span>
                        <!-- 木紋依照長邊走 -->
                        <span
                          v-if="scope.row.orientation == 2"
                          class="glyphicon glyphicon-resize-vertical orientation-height"
                        ></span>
                        <!-- 不須考慮木紋方向 -->
                        <span
                          v-if="scope.row.orientation == 0"
                          class="glyphicon glyphicon-asterisk orientation-star"
                        ></span>
                      </el-button>
                      <el-checkbox
                        class="el-cus-checkbox"
                        v-if="isShowControls(scope.row)"
                        v-model="scope.row.enabled"
                        @change="checkChange(scope.row)"
                      ></el-checkbox>
                      <el-button
                        style="margin-left: 5px"
                        v-if="isShowControls(scope.row)"
                        type="text"
                        size="mini"
                        @click="tableDel(scope.row)"
                        class="orientation"
                      >
                        <span
                          class="glyphicon glyphicon-remove table-del"
                        ></span>
                      </el-button>
                    </div>
                  </template>
                </x-table>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Feedback" name="2">
              <template #title>
                <div class="title">
                  <i class="icon2 el-icon-s-grid"></i>
                  板料尺寸
                </div>
              </template>
              <div>
                <x-table
                  :dataSource="banTableData"
                  :cloumns="cloumns"
                  @cell-click="banTotalCountcellClick"
                  @controlHandler="banControlHandler"
                  class="mytablecss"
                >
                  <template #width="{ scope }">
                    <div v-if="banTableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.width"
                        @blur="banWidthblur(scope.row)"
                        @input="isBanAddRow(scope)"
                        type="number"
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.width }}</div>
                  </template>
                  <template #height="{ scope }">
                    <div v-if="banTableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.height"
                        @blur="banWidthblur(scope.row)"
                        @input="isBanAddRow(scope)"
                        type="number"
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.height }}</div>
                  </template>
                  <template #count="{ scope }">
                    <div v-if="banTableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.count"
                        @blur="banWidthblur(scope.row)"
                        @input="
                          (val) => (scope.row.count = val.replace(/\D/g, ''))
                        "
                        type="number"
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.count }}</div>
                  </template>
                  <template #material="{ scope }">
                    <div v-if="banTableCellClickRowIndexHandle(scope)">
                      <select
                        name=""
                        id=""
                        @blur="selBanWidthblur(scope.row)"
                        v-model="scope.row.material"
                        @change="selectBanChange($event, scope.row)"
                        style="width: 100%"
                        :ref="'ban' + scope.row.id"
                      >
                        <option
                          v-for="(item, index) in materialSelects"
                          :value="item.value"
                          :key="index"
                        >
                          {{ item.key }}
                        </option>
                      </select>
                    </div>
                    <div v-else>{{ scope.row.material }}</div>
                  </template>
                  <template #label="{ scope }">
                    <div v-if="banTableCellClickRowIndexHandle(scope)">
                      <el-input
                        class="mtinput"
                        :ref="'ban' + scope.row.id"
                        v-model="scope.row.label"
                        @blur="banWidthblur(scope.row)"
                      ></el-input>
                    </div>
                    <div v-else>{{ scope.row.label }}</div>
                  </template>
                  <template #control="{ scope }">
                    <div class="contro_orientation">
                      <el-button
                        type="text"
                        size="mini"
                        class="orientation"
                        @click="banOrientationClick(scope.row)"
                        v-if="
                          configurationData.considerOrientation &&
                          isBanShowControls(scope.row)
                        "
                      >
                        <!-- 木纹依照宽边走 -->
                        <span
                          v-if="scope.row.orientation == 1"
                          class="glyphicon glyphicon-resize-horizontal orientation-width"
                        ></span>
                        <!-- 木紋依照長邊走 -->
                        <span
                          v-if="scope.row.orientation == 2"
                          class="glyphicon glyphicon-resize-vertical orientation-height"
                        ></span>
                        <!-- 不須考慮木紋方向 -->
                        <span
                          v-if="scope.row.orientation == 0"
                          class="glyphicon glyphicon-asterisk orientation-star"
                        ></span>
                      </el-button>
                      <el-checkbox
                        class="el-cus-checkbox"
                        v-if="isBanShowControls(scope.row)"
                        v-model="scope.row.enabled"
                        @change="banCheckChange(scope.row)"
                      ></el-checkbox>
                      <el-button
                        style="margin-left: 5px"
                        v-if="isBanShowControls(scope.row)"
                        type="text"
                        size="mini"
                        @click="banTableDel(scope.row)"
                        class="orientation"
                      >
                        <span
                          class="glyphicon glyphicon-remove table-del"
                        ></span>
                      </el-button>
                    </div>
                  </template>
                </x-table>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Efficiency" name="3">
              <template #title>
                <div class="title">
                  <i class="icon2 el-icon-s-tools"></i>
                  选项
                </div>
              </template>
              <div class="other">
                <el-form label-width="160px">
                  <el-form-item label="锯片厚度">
                    <div class="item-label">
                      <el-input
                        class="mtinput"
                        style="width: 50px"
                        v-model="form.cutThickness"
                        @blur="cutThicknessBlur"
                        type="number"
                      ></el-input>
                    </div>
                  </el-form-item>
                  <el-form-item label="标示部件名称">
                    <div class="item-label">
                      <el-switch
                        @change="cutThicknessBlur('isTileLabelVisible')"
                        v-model="form.isTileLabelVisible"
                      ></el-switch>
                    </div>
                  </el-form-item>
                  <!-- <el-form-item label="从板料数量择一使用">
                    <div class="item-label">
                      <el-switch
                        @change="cutThicknessBlur"
                        v-model="form.useSingleStockUnit"
                      ></el-switch>
                    </div>
                  </el-form-item> -->
                  <el-form-item label="指定材料">
                    <div class="item-label">
                      <el-switch
                        @change="cutThicknessBlur('isMaterialEnabled')"
                        v-model="form.isMaterialEnabled"
                      ></el-switch>
                    </div>
                  </el-form-item>
                  <!-- <el-form-item label="对边条">
                    <div class="item-label">
                      <el-switch
                        @change="cutThicknessBlur"
                        v-model="form.hasEdgeBanding"
                      ></el-switch>
                    </div>
                  </el-form-item> -->
                  <el-form-item label="指定木纹方向">
                    <div class="item-label">
                      <el-switch
                        @change="cutThicknessBlur"
                        v-model="form.considerOrientation"
                      ></el-switch>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="resize" title="收缩侧边栏">&nbsp;</div>
      </div>

      <div class="mid">
        <div class="mid-content">
          <cut-svg :dataSource="cutSvgData" ref="cutDom"></cut-svg>
        </div>
      </div>
    </div>
    <input
      style="display: none"
      type="file"
      id="files"
      ref="refFile"
      @change="importCsv(importTableType)"
      accept=".csv"
    />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import CutSvg from "@/components/CutSvg.vue";
import Pdf from "@/views/Pdf.vue";
import Papa from "papaparse";
import {
  addBanProjectData,
  addProjectData,
  removeProjectData,
  removeBanProjectData,
  replaceTableData,
  getClearData,
  handlerBanData,
  uniqueFunc,
} from "@/tools/utils";
import AES from "@/tools/AES";

import modelData from "@/tools/model";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    NavBar,
    CutSvg,
    Pdf,
  },
  computed: {
    isShowControls() {
      return (row) => {
        return (
          (row.label && row.label != " ") ||
          (row.width && row.width != " ") ||
          (row.height && row.height != " ") ||
          (row.count && row.count != " ")
        );
      };
    },
    isBanShowControls() {
      return (row) => {
        return (
          (row.label && row.label != " ") ||
          (row.width && row.width != " ") ||
          (row.height && row.height != " ") ||
          (row.count && row.count != " ")
        );
      };
    },
    tableCellClickRowIndexHandle() {
      return (scope) => {
        return (
          scope.row.index === this.tableCellClickRowIndex &&
          scope.column.index === this.tableCellClickColumnIndex
        );
      };
    },
    banTableCellClickRowIndexHandle() {
      return (scope) => {
        return (
          scope.row.index === this.banTableCellClickRowIndex &&
          scope.column.index === this.banTableCellClickColumnIndex
        );
      };
    },
    selectTableCellClickRowIndexHandle() {
      return (scope) => {
        return (
          scope.row.index === this.tableCellClickRowIndex &&
          scope.column.index === this.tableCellClickColumnIndex
        );
      };
    },
    upDateTableData() {
      return this.$store.state.leftData;
    },
    configurationData() {
      return this.$store.getters.configurationData;
    },
  },
  watch: {
    activeNames(newV) {
      this.$refs.table1.$ready = false;
    },
    upDateTableData: {
      handler(nval, oval) {
        if (nval && typeof nval === "string") {
          var data = JSON.parse(nval);
          if (data && data.planSmall) {
            this.tableData = data.planSmall;
          }
          if (data && data.planBig) {
            this.banTableData = data.planBig;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    configurationData: {
      handler(nval, oval) {
        this.tempChangeCloumns();
        var curData = JSON.parse(JSON.stringify(this.$store.getters.result));
        this.cutSvgData = this.isFold(curData);
        this.$store.dispatch("cutData", this.cutSvgData.solution);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      tableCellClickRowIndex: null,
      tableCellClickColumnIndex: null,
      banTableCellClickRowIndex: null,
      banTableCellClickColumnIndex: null,
      activeNames: ["1", "2", "3"],
      cloumns: [
        {
          field: "width",
          title: "宽",
          slot: "width",
        },
        {
          field: "height",
          title: "长",
          slot: "height",
        },
        {
          field: "count",
          title: "数量",
          slot: "count",
        },
        {
          field: "material",
          title: "材料",
          slot: "material",
          isHiden: true,
        },
        {
          field: "label",
          title: "部件名称",
          slot: "label",
        },
        {
          field: "control",
          title: "",
          slot: "control",
          showHeader: true,
          align: "center",
        },
      ],
      tableData: [],
      banTableData: [],
      form: {
        cutThickness: "",
        isTileLabelVisible: false,
        useSingleStockUnit: false,
        isMaterialEnabled: false,
        hasEdgeBanding: false,
        considerOrientation: false,
      },
      projectName: "",
      taskId: "",
      timer: "",
      leftLoading: false,
      resultStatus: "",
      cutSvgData: {},
      importTableType: "small",
      resultTitle: `正在寻找最佳方案...0%`,
      materialSelects: [
        { key: "无", value: "" },
        { key: "-添加材料-", value: "-添加材料-" },
      ],
      isSelectedChange: false,
    };
  },
  mounted() {
    this.projectName = this.$store.getters.projectName;
    //初始化绘图数据
    var params = this.$store.getters.configurationData;
    if (this.$store.getters.result) {
      //折叠数据处理
      var curData = JSON.parse(JSON.stringify(this.$store.getters.result));
      this.cutSvgData = this.isFold(curData);
      this.$store.dispatch("cutData", this.cutSvgData.solution);
    }

    //初始化左侧列表数据
    this.$store.dispatch("getPartObjectData").then((res) => {
      this.tableData = res.planSmall;
      this.banTableData = res.planBig;
      //列表数据添加
      res.planSmall.forEach((val) => {
        if (val.material) {
          this.materialSelects.splice(1, 0, {
            key: val.material,
            value: val.material,
          });
        }
      });
      res.planBig.forEach((val) => {
        if (val.material) {
          this.materialSelects.splice(1, 0, {
            key: val.material,
            value: val.material,
          });
        }
      });
      this.materialSelects = uniqueFunc(this.materialSelects, "key");
    });

    this.dragControllerDiv();
    this.$EventBus.$on("play", (status) => {
      if (status) {
        this.beginPlay();
      } else {
        this.leftLoading = false;
        this.$store.dispatch("playStatus", false);
      }
    });
    for (const key in this.form) {
      if (params[key] != null) {
        const element = params[key];
        this.form[key] = element;
      }
    }
    this.tileLabelVisible();
    this.materialEnabled();
    this.tempChangeCloumns();
  },
  methods: {
    //当最后一行输入的时候新增一行
    isAddRow(scope) {
      if (
        scope.$index === this.tableData.length - 1 &&
        scope.column.index === 0
      ) {
        if (scope.row[this.cloumns[0].field].length === 1) {
          this.$store.dispatch("saveLocalData", {
            planSmall: this.tableData,
          });
          addProjectData(this.projectName);
        }
      }
    },
    isBanAddRow(scope) {
      if (
        scope.$index === this.banTableData.length - 1 &&
        scope.column.index === 0
      ) {
        if (scope.row[this.cloumns[0].field].length === 1) {
          this.$store.dispatch("saveLocalData", {
            planBig: this.banTableData,
          });
          addBanProjectData(this.projectName);
        }
      }
    },
    foldMosaicsHandler(mosaics) {
      var arrMap = new Map();
      var countMap = new Map();
      var strArr = [];

      mosaics.forEach((element) => {
        element.count = 1;
        var strElement = handlerBanData(element);
        if (strArr.indexOf(strElement) > -1) {
          var count = countMap.get(strElement);
          countMap.set(strElement, count + 1);
        }

        if (strArr.indexOf(strElement) === -1) {
          strArr.push(strElement);
          countMap.set(strElement, 1);
        }
        element.count = countMap.get(strElement);
        arrMap.set(strElement, element);
      });
      // console.log(arrMap);
      var newMosaics = [];
      arrMap.forEach((val) => {
        newMosaics.push(val);
      });

      return newMosaics;
    },
    tempChangeCloumns() {
      var widthItem = {
        field: "width",
        title: "宽",
        slot: "width",
      };
      var heightItem = {
        field: "height",
        title: "长",
        slot: "height",
      };
      var copyCloumns = JSON.parse(JSON.stringify(this.cloumns));
      if (!this.$store.getters.configurationData.isWidthFirst) {
        copyCloumns.splice(0, 1, heightItem);
        copyCloumns.splice(1, 1, widthItem);
      } else {
        copyCloumns.splice(0, 1, widthItem);
        copyCloumns.splice(1, 1, heightItem);
      }
      this.cloumns = copyCloumns;
    },
    totalCountcellClick(row, column, cell, event) {
      this.tableCellClickRowIndex = row.index;
      this.tableCellClickColumnIndex = column.index;
      this.$nextTick(() => {
        if (this.$refs["ban" + row.id]) {
          this.$refs["ban" + row.id].focus();
        }
      });
    },
    banTotalCountcellClick(row, column) {
      this.banTableCellClickRowIndex = row.index;
      this.banTableCellClickColumnIndex = column.index;
      this.$nextTick(() => {
        if (this.$refs["ban" + row.id]) {
          this.$refs["ban" + row.id].focus();
        }
      });
    },
    widthblur(row) {
      this.tableCellClickRowIndex = null;
      this.tableCellClickColumnIndex = null;
      if (this.isShowControls(row)) {
        row.enabled = true;
      } else {
        row.enabled = false;
      }
      this.$store.dispatch("saveLocalData", {
        planSmall: this.tableData,
      });
    },
    selWidthblur(row) {
      setTimeout(() => {
        this.tableCellClickRowIndex = null;
        this.tableCellClickColumnIndex = null;
        if (this.isShowControls(row)) {
          row.enabled = true;
        } else {
          row.enabled = false;
        }
      }, 200);
      this.$store.dispatch("getPartObjectData").then((res) => {
        res.planSmall.forEach((val) => {
          row.material = val;
        });
      });
    },
    selBanWidthblur(row) {
      setTimeout(() => {
        this.banTableCellClickRowIndex = null;
        this.banTableCellClickRowIndex = null;
        if (this.isShowControls(row)) {
          row.enabled = true;
        } else {
          row.enabled = false;
        }
      }, 200);
      this.$store.dispatch("getPartObjectData").then((res) => {
        res.planBig.forEach((val) => {
          row.material = val;
        });
      });
    },
    selectChange(val, row) {
      if (val.target.value === "-添加材料-") {
        this.$prompt("名称", "添加材料", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            this.$set(row, "material", value);
            this.materialSelects.splice(1, 0, { key: value, value: value });
            console.log(this.tableData);
            this.tableData.forEach((val) => {
              if (val.id === row.id) {
                val.material = value;
              }
            });
            this.$store.dispatch("saveLocalData", {
              planSmall: this.tableData,
            });
          })
          .catch(() => {});
      } else {
        row.material = val.target.value;
        this.$store.dispatch("saveLocalData", {
          planSmall: this.tableData,
        });
      }
    },
    selectBanChange(val, row) {
      if (val.target.value === "-添加材料-") {
        this.$prompt("名称", "添加材料", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            // row.material = value;
            this.$set(row, "material", value);
            this.materialSelects.splice(1, 0, { key: value, value: value });
            this.banTableData.forEach((val) => {
              if (val.id === row.id) {
                val.material = value;
              }
            });
            this.$store.dispatch("saveLocalData", {
              planBig: this.banTableData,
            });
          })
          .catch(() => {});
      } else {
        row.material = val.target.value;
        this.$store.dispatch("saveLocalData", {
          planBig: this.banTableData,
        });
      }
    },
    banWidthblur(row) {
      this.banTableCellClickRowIndex = null;
      this.banTableCellClickColumnIndex = null;
      if (this.isShowControls(row)) {
        row.enabled = true;
      } else {
        row.enabled = false;
      }
      this.$store.dispatch("saveLocalData", {
        planBig: this.banTableData,
      });
    },
    orientationClick(row) {
      this.orientationHandle(row);
      this.$store.dispatch("saveLocalData", {
        planSmall: this.tableData,
      });
    },
    banOrientationClick(row) {
      this.orientationHandle(row);
      this.$store.dispatch("saveLocalData", {
        planBig: this.banTableData,
      });
    },
    orientationHandle(row) {
      row.orientation = row.orientation + 1;
      if (row.orientation > 2) {
        row.orientation = 0;
      }
      if (row.orientation == 0) {
        this.$notify({
          title: "",
          message: "不须考虑木纹方向 - " + row.height + "x" + row.width,
        });
      } else if (row.orientation == 1) {
        this.$notify({
          title: "",
          message: "木纹依照宽边走 - " + row.height + "x" + row.width,
        });
      } else if (row.orientation == 2) {
        this.$notify({
          title: "",
          message: "木纹依照长边走 - " + row.height + "x" + row.width,
        });
      }
    },
    checkChange() {
      this.$store.dispatch("saveLocalData", {
        planSmall: this.tableData,
      });
    },
    banCheckChange() {
      this.$store.dispatch("saveLocalData", {
        planBig: this.banTableData,
      });
    },
    dragControllerDiv() {
      var resize = document.getElementsByClassName("resize");
      var left = document.getElementsByClassName("left");
      var mid = document.getElementsByClassName("mid");
      var box = document.getElementsByClassName("box");
      var that = this;
      for (let i = 0; i < resize.length; i++) {
        // 鼠标按下事件
        resize[i].onmousedown = function (e) {
          //颜色改变提醒
          // resize[i].style.background = "#818181";
          var startX = e.clientX;
          resize[i].left = resize[i].offsetLeft;
          // 鼠标拖动事件
          document.onmousemove = function (e) {
            setTimeout(() => {
              that.$EventBus.$emit("dragEvent");
            }, 300);
            var endX = e.clientX;
            var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
            var maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

            if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
            if (moveLen > maxT - 150) moveLen = maxT - 150; //右边区域最小宽度为150px

            resize[i].style.left = moveLen; // 设置左侧区域的宽度

            for (let j = 0; j < left.length; j++) {
              left[j].style.width = moveLen + "px";
              mid[j].style.width = box[i].clientWidth - moveLen - 10 + "px";
            }
          };
          // 鼠标松开事件
          document.onmouseup = function () {
            //颜色恢复
            // resize[i].style.background = "#d6d6d6";
            document.onmousemove = null;
            document.onmouseup = null;
            resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          };

          resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false;
        };
      }
    },
    controlHandler(index) {
      if (index === "csv_export") {
        this.exportCsv(this.tableData, "部件尺寸");
      } else if (index === "add") {
        addProjectData(this.projectName);
      } else if (index === "csv_import") {
        this.importTableType = "small";
        this.$refs.refFile.click();
      } else if (index === "clear") {
        replaceTableData({ name: this.projectName, planSmall: getClearData() });
      }
    },
    banControlHandler(index) {
      if (index === "csv_export") {
        this.exportCsv(this.banTableData, "板料尺寸");
      } else if (index === "add") {
        addBanProjectData(this.projectName);
      } else if (index === "csv_import") {
        this.importTableType = "big";
        this.$refs.refFile.click();
      } else if (index === "clear") {
        replaceTableData({ name: this.projectName, planBig: getClearData() });
      }
    },
    // 键盘事件
    tableRowInputKeyup(e, index, titleName) {
      console.log(e);
      if (e.code === "Tab") {
        e.preventDefault();
        let refName = "";
        switch (e.code) {
          case "Tab":
            if (titleName === "title0") {
              refName = "table" + index + "title1";
            }
            if (titleName === "title1") {
              refName = "table" + index + "title2";
            }
            if (titleName === "title2") {
              refName = "table" + (index + 1) + "title3";
            }
            if (titleName === "title3") {
              refName = "table" + (index + 1) + "title4";
            }
            if (titleName === "title4") {
              refName = "table" + (index + 1) + "title0";
            }
            break;
          default:
            break;
        }
        if (this.$refs[refName]) {
          // 下一个input的dom存在，就切换到该dom
          this.$refs[refName].focus();
          this.$refs[refName].select();
        } else if (index && index === this.tableData.length - 1) {
          // 索引存在，但是已经是最后一个了，则又跳转到第一个input的Dom去
          refName = "table" + 0 + "title0";
          this.$refs[refName].focus();
          this.$refs[refName].select();
        }
      }
    },
    exportHandle(tableData) {
      var newTable = [];
      tableData.forEach((val) => {
        delete val.id;
        delete val.index;
        var newObj = {};
        for (const key in val) {
          if (key === "height") {
            newObj.Length = val[key];
          } else if (key === "width") {
            newObj.Width = val[key];
          } else if (key === "count") {
            newObj.Qty = val[key];
          } else if (
            key === "material" &&
            this.configurationData.isMaterialEnabled
          ) {
            newObj.Material = val[key];
          } else if (
            key === "label" &&
            this.configurationData.isTileLabelVisible
          ) {
            newObj.Label = val[key];
          } else if (key === "enabled") {
            newObj.Enabled = val[key];
          }
          delete newObj.height;
        }
        newTable.push(newObj);
      });
      return newTable;
    },
    //导出csv
    exportCsv(tableData, name = "文件导出") {
      var csv = "\ufeff" + Papa.unparse(this.exportHandle(tableData));
      //定义文件内容，类型必须为Blob 否则createObjectURL会报错
      let content = new Blob([csv], {
        type: "text/plain;charset=utf-8;",
      });
      //生成url对象
      let urlObject = window.URL || window.webkitURL || window;
      let url = urlObject.createObjectURL(content);
      //生成<a></a>DOM元素
      let el = document.createElement("a");
      //链接赋值
      el.href = url;
      el.download = name + ".csv";
      //必须点击否则不会下载
      el.click();
      //移除链接释放资源
      urlObject.revokeObjectURL(url);
    },
    // 导入csv
    importCsv(type) {
      let selectedFile = null;
      selectedFile = this.$refs.refFile.files[0];
      if (selectedFile === undefined) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (evt) => {
        // 检查编码
        let encoding = this.checkEncoding(evt.target.result);
        // 将csv转换成二维数组
        Papa.parse(selectedFile, {
          encoding: encoding,
          complete: (res) => {
            // UTF8 \r\n与\n混用时有可能会出问题
            let data = res.data;
            if (data[data.length - 1] == "") {
              //去除最后的空行
              data.pop();
            }
            console.log(data); // data就是文件里面的数据
            if (data.length > 0) {
              var titles = this.importCsvTitleHandle(data[0]);
              var copyData = JSON.parse(JSON.stringify(data));
              copyData.splice(0, 1);
              var valArr = [];
              copyData.forEach((element, index) => {
                var form = {};
                element.forEach((val, index2) => {
                  if (typeof val === "string" && val) {
                    val = JSON.parse(JSON.stringify(val));
                  }
                  if (titles[index2] === "height") {
                    form[titles[index2]] = val.replace(/,/g, "");
                  } else if (titles[index2] === "width") {
                    form[titles[index2]] = val.replace(/,/g, "");
                  } else if (titles[index2] === "enabled") {
                    if (val === "TRUE" || val === true || val === "true") {
                      form[titles[index2]] = true;
                    } else if (!val || val === "FALSE" || val === "false") {
                      form[titles[index2]] = false;
                    }
                  } else {
                    form[titles[index2]] = val;
                  }
                });
                form.id = 1000 + index + 1;
                valArr.push(form);
              });
              if (type === "small") {
                this.tableData = valArr;
                replaceTableData({
                  name: this.projectName,
                  planSmall: this.tableData,
                });
              } else if (type === "big") {
                this.banTableData = valArr;
                replaceTableData({
                  name: this.projectName,
                  planBig: this.banTableData,
                });
              }
            }
          },
        });
      };
    },
    importCsvTitleHandle(data) {
      var newData = [];
      data.forEach((val) => {
        // Length	Width	Qty	Material	Label	Enabled
        if (val === "Length") {
          newData.push("height");
        } else if (val === "Width") {
          newData.push("width");
        } else if (val === "Qty") {
          newData.push("count");
        } else if (val === "Material") {
          newData.push("material");
        } else if (val === "Label") {
          newData.push("label");
        } else if (val === "Enabled") {
          newData.push("enabled");
        }
      });

      if (newData.length === 0) {
        newData = ["height", "width", "count", "material", "label", "enabled"];
        if (!this.configurationData.isMaterialEnabled) {
          newData = ["height", "width", "count", "label", "enabled"];
        }
        if (!this.configurationData.isTileLabelVisible) {
          newData = ["height", "width", "count", "material", "enabled"];
        }
        if (
          !this.configurationData.isTileLabelVisible &&
          !this.configurationData.isMaterialEnabled
        ) {
          newData = ["height", "width", "count", "enabled"];
        }
      } else {
        if (this.configurationData.isMaterialEnabled) {
          newData.splice(3, 0, "material");
        }
        if (
          this.configurationData.isTileLabelVisible &&
          !this.configurationData.isMaterialEnabled
        ) {
          newData.splice(3, 0, "label");
        } else if (
          this.configurationData.isTileLabelVisible &&
          this.configurationData.isMaterialEnabled
        ) {
          newData.splice(4, 0, "label");
        }
      }

      return newData;
    },
    checkEncoding(base64Str) {
      // 这种方式得到的是一种二进制串
      var str = atob(base64Str.split(";base64,")[1]);
      // console.log(str);
      // 要用二进制格式
      const jschardet = require("jschardet");
      var encoding = jschardet.detect(str);
      encoding = encoding.encoding;
      // console.log( encoding );
      if (encoding === "windows-1252") {
        // 有时会识别错误（如UTF8的中文二字）
        encoding = "ANSI";
      }
      return encoding;
    },
    tableDel(row) {
      removeProjectData(this.projectName, row.id);
    },
    banTableDel(row) {
      removeBanProjectData(this.projectName, row.id);
    },
    //开始计算
    beginPlay() {
      var params = {
        configuration: this.configurationData,
        panels: [],
        stockPanels: [],
      };
      params.panels = JSON.parse(
        JSON.stringify(this.tableData.filter((val) => val.enabled))
      );
      params.stockPanels = JSON.parse(
        JSON.stringify(this.banTableData.filter((val) => val.enabled))
      );
      var isError = true;
      params.panels.forEach((val) => {
        params.stockPanels.forEach((sVal) => {
          if (val.height * val.width < sVal.height * sVal.width) {
            isError = false;
          }
        });
      });
      if (isError) {
        this.$confirm(
          "无法在可用的库存表上安装指定的面板。 所需的面板比库存板材大！指定的尺寸是否错位？ 面板列表是否应该与库存表列表交换？",
          "提示",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "好",
            cancelButtonText: "取消",
          }
        )
          .then(() => {
            this.$store.dispatch("saveLocalData", {
              planSmall: this.banTableData,
              planBig: this.tableData,
            });
            beginPlay();
            this.isBeginPlay(params);
          })
          .catch((action) => {
            this.isBeginPlay(params);
          });
      } else {
        this.isBeginPlay(params);
      }
    },
    isBeginPlay(params) {
      if (params.panels.length === 0) {
        this.$message.error("没有选定部件！");
        return;
      } else if (params.stockPanels.length === 0) {
        this.$message.error("没有选定板料！");
        return;
      } else {
        this.resultTitle = `正在寻找最佳方案...0%`;
        if (!this.configurationData.isMaterialEnabled) {
          params.panels.forEach((val) => {
            val.material = "DEFAULT_MATERIAL";
          });
          params.stockPanels.forEach((val) => {
            val.material = "DEFAULT_MATERIAL";
          });
        }
        this.$EventBus.$emit("clearSvgDom")
        this.getTasks(params);
      }
    },
    cutThicknessBlur(key) {
      var params = this.$store.getters.configurationData;
      for (const key in this.form) {
        const element = this.form[key];
        params[key] = element;
      }
      this.$store.dispatch("configuration", JSON.stringify(params));
      this.handleOtherSetting(key);
    },
    handleOtherSetting(key) {
      if (key === "isTileLabelVisible") {
        this.tileLabelVisible();
      }
      if (key === "isMaterialEnabled") {
        this.materialEnabled();
      }
    },
    tileLabelVisible() {
      if (this.form.isTileLabelVisible) {
        this.$set(this.cloumns[4], "isHiden", false);
      } else {
        this.$set(this.cloumns[4], "isHiden", true);
      }
    },
    materialEnabled() {
      if (this.form.isMaterialEnabled) {
        this.$set(this.cloumns[3], "isHiden", false);
      } else {
        this.$set(this.cloumns[3], "isHiden", true);
      }
    },
    getTasks(params) {
      this.leftLoading = true;
      this.$store.dispatch("playStatus", true);
      this.$request
        .compute(params)
        .then((res) => {
          if (res.code === "200") {
            this.taskId = res.data.encrypt;
            this.runTimer();
          } else {
            this.leftLoading = false;
            this.$store.dispatch("playStatus", false);
          }
        })
        .catch((reason) => {
          console.log(reason.message);
        });
    },
    runTimer() {
      this.timer = setInterval(() => {
        this.getResult();
      }, 1000);
    },
    getResult() {
      clearInterval(this.timer);
      this.timer = null;
      var params = {
        taskId: this.taskId,
      };
      this.$request
        .getResult(params)
        .then((res) => {
          if (res.code === "200") {
            this.resultTitle = `正在寻找最佳方案...${res.data.encrypt.percentageDone}%`;
            if (res.data.encrypt.status === "RUNNING" || res.data.encrypt.status === "IDLE") {
              if (res.data.encrypt.solution) {
                this.runTimer();
                this.$store.dispatch("playStatus", true);
                this.cutSvgData = this.isFold(res.data.encrypt);
              } else {
                this.leftLoading = false;
                this.$store.dispatch("playStatus", false);
              }
            } else if (res.data.encrypt.status === "FINISHED") {
              this.leftLoading = false;
              this.$store.dispatch("playStatus", false);
              this.cutSvgData = this.isFold(res.data.encrypt);
            }
          } else {
            this.leftLoading = false;
            this.$store.dispatch("playStatus", false);
          }
        })
        .catch((reason) => {
          console.log(reason.message);
        });
    },
    isFold(cutSvgData) {
      if (
        this.configurationData.stackEqualMosaicLayout === 0 ||
        this.configurationData.stackEqualMosaicLayout === 1
      ) {
        if (cutSvgData.solution && cutSvgData.solution.mosaics.length) {
          var mosaics = this.foldMosaicsHandler(cutSvgData.solution.mosaics);
          cutSvgData.solution.mosaics = mosaics;
        }
      }
      return cutSvgData;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.app-main {
  height: calc(100% - 40px);
  width: 100%;
  // height: auto;
}

/* 拖拽相关样式 */
/*包围div样式*/
.box {
  width: 100%;
  height: 100%;
  margin: 0% 0px;
  overflow: hidden;
  /* box-shadow: -1px 9px 10px 3px rgba(0, 0, 0, 0.11); */
}

/*左侧div样式*/
.left {
  width: calc(40%);
  /*左侧初始化宽度*/
  height: 100%;
  background: #ffffff;
  float: left;
  display: flex;
  position: relative;
  box-shadow: -1px 9px 10px 0.5px rgba(0, 0, 0, 0.35);
}

/*拖拽区div样式*/
.resize {
  cursor: col-resize;
  position: absolute;
  /* background-color: #d6d6d6; */
  right: 0;
  width: 1px;
  height: 100%;
  background-size: cover;
  background-position: center;
  /*z-index: 99999;*/
  font-size: 32px;
  color: white;
}

/*拖拽区鼠标悬停样式*/
.resize:hover {
  /* color: #444444; */
}

/*右侧div'样式*/
.mid {
  float: left;
  width: 60%;
  /*右侧初始化宽度*/
  height: 100%;
  background: #fff;
  /* box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11); */
}

.left-content {
  width: calc(100% - 2px);
  // padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}

.mid-content {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.title {
  width: 100%;
  height: 100%;
  background: $title-background;
  display: flex;
  align-items: center;
  color: $font-color;
  font-size: 15px;

  .icon {
    width: 20px;
    margin-right: 1px;
    margin-left: 10px;
    position: relative;
    top: 0px !important;
    font-size: 17px;
  }

  .icon2 {
    width: 20px;
    margin-right: 1px;
    margin-left: 10px;
    position: relative;
    font-size: 17px;
  }

  img {
    width: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.other {
  width: 300px;
  float: right;
  margin-right: 10px;
  margin-top: 6px;
}

.item-label {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 34px;
}
.mtinput {
  /deep/ .el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
    padding: 0 5px !important;
    color:#333333
  }
  /deep/ .el-input__suffix {
    display: flex;
    align-items: center;
  }
  color: #333333
}
.contro_orientation {
  display: flex;
  align-items: center;
  height: 24px;
}
.orientation {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orientation-width:before {
  color: #1565c0;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.orientation-height:before {
  color: #1565c0;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  vertical-align: middle;
}
.orientation-star:before {
  color: #1565c0;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  vertical-align: middle;
}
.table-del:before {
  color: #c62828;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  vertical-align: middle;
}
.el-cus-checkbox {
  margin-left: 5px;
  position: relative;
  top: 1.5px;
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #2e7d32;
    border-color: #2e7d32;
  }
}
.mytablecss {
  /deep/
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: rgb(200, 230, 201) !important;
  }
 /deep/ .el-table{
  color:#333333
 } 
}
</style>
<style lang="scss">
.el-collapse-item__header {
  background: $title-background !important;
  height: 28px !important;
  color: #000;
}
</style>
