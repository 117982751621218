<template>
  <div style="height: auto" v-if="isShow">
    <!-- <el-button @click="handleOutput">导出</el-button> -->
    <div class="swipe-outside">
      <div class="swipe pdfDom" ref="pdfDom">
        <div class="divide-inside">
          <div class="line"></div>
          <div class="info">
            <div style="float: left" v-if="totalTableData.length">
              <el-form label-width="120px" label-position="left">
                <el-form-item label="用掉板料">{{
                  this.totalTableData[0].val
                }}</el-form-item>
                <el-form-item label="使用总面积">{{
                  this.totalTableData[1].val
                }}</el-form-item>
                <el-form-item label="剩余总面积">{{
                  this.totalTableData[2].val
                }}</el-form-item>
                <el-form-item label="裁切总数">{{
                  this.totalTableData[3].val
                }}</el-form-item>
                <el-form-item label="裁切长">{{
                  this.totalTableData[4].val
                }}</el-form-item>
                <el-form-item label="锯片厚度">{{
                  totalTableData[5].val
                }}</el-form-item>
              </el-form>
            </div>
            <div style="float: left" v-if="totalTableData.length">
              <el-form label-width="120px">
                <el-form-item label="部件尺寸">{{
                  totalTableData[6].val
                }}</el-form-item>
                <el-form-item label="板料尺寸">{{
                  totalTableData[7].val
                }}</el-form-item>
              </el-form>
            </div>
          </div>
          <div class="line"></div>
        </div>

        <div
          v-for="(item, index) in this.itemObject.mosaics"
          :key="index"
          :class="index === 0 ? 'size-box' : 'size-box divide-inside'"
          :style="{
            'padding-top': (index === 0 ? 25 : (index + 1) * 30) + 'px',
          }"
        >
          <div class="table-info">
            <x-table
              :dataSource="banTableDataCp(index)"
              :cloumns="cloumns1"
              :border="false"
              class="tablecss"
            >
            </x-table>
            <x-table
              style="margin-top: 20px"
              :dataSource="panelsTableDataHandler(index)"
              :cloumns="cloumns2"
              :border="false"
            >
              <template #size="{ scope }">
                <span>{{ scope.row.height }}x{{ scope.row.width }}</span>
              </template>
            </x-table>
            <x-table
              style="
                margin-top: 20px;
                max-height: 700px !important;
                overflow: hidden;
              "
              :dataSource="item.cuts"
              :cloumns="cloumns3"
              :border="false"
            >
              <template #indexx="{ scope }">{{
                parseInt(scope.$index) + 1
              }}</template>
              <template #size="{ scope }">
                <span
                  >{{ scope.row.originalHeight }}x{{
                    scope.row.originalWidth
                  }}</span
                >
              </template>
              <template #position="{ scope }">
                <span
                  >{{ scope.row.horizontal ? "x=" : "y="
                  }}{{ scope.row.cutCoord }}</span
                >
              </template>
              <template #result="{ scope }">
                <span>{{
                  scope.row.cutCoord + "x" + scope.row.originalHeight
                }}</span>
              </template>
            </x-table>
          </div>
          <div class="example">
            <SetSvgDom
              :data-source="itemObjectHandler(item, index)"
              :useWidthScale="true"
              :refName="'pdf' + index"
              :itemWidth="item.cuts[0].originalWidth"
            />
          </div>
        </div>

        <div>
          <!-- <div class="line"></div> -->
        </div>
      </div>
      <div
        class="pdf-header"
        style="
          font-weight: bold;
          padding: 15px 8px;
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.85);
          color: rgba(0, 0, 0, 0.85);
          position: fixed;
          top: -100vh;
        "
      >
        {{ pdfForm.headerText }}
      </div>
      <div
        class="pdf-footer"
        style="
          font-weight: bold;
          padding: 15px 8px;
          width: 100%;
          border-top: 1px solid rgba(0, 0, 0, 0.85);
          position: fixed;
          top: -100vh;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 5px;
          "
        ></div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
          "
        >
          第
          <div class="pdf-footer-page"></div>
          页 / 第
          <div class="pdf-footer-page-count"></div>
          页
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetSvgDom from "@/components/SetSvgDom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { outputPDF } from "@/tools/outputPDF";

import { handlerBanData, areaUnitHandle } from "@/tools/utils";

export default {
  components: {
    SetSvgDom,
  },
  props: {
    cutVals: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    itemObjectHandler() {
      return (item, index) => {
        var copyItemObject = JSON.parse(JSON.stringify(this.itemObject));
        copyItemObject.mosaics = [item];
        return copyItemObject;
      };
    },
    getHeight() {
      return (index) => {
        var height = this.A4_HEIGHT;
        if (index === 0) {
          height = this.A4_HEIGHT - 260;
        }
        return height;
      };
    },
    banTableDataCp() {
      return (index) => {
        return this.getBanData(index);
      };
    },
    panelsTableDataHandler() {
      return (index) => {
        return this.itemObject.mosaics[index].panels;
      };
    },
  },
  watch: {
    cutVals: {
      handler(nVal, oVal) {
        // console.log(nVal);
        this.itemObject = nVal;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isShow: false,
      cloumns1: [
        { field: "name", title: "板料尺寸" },
        { field: "val", title: "66-1000x1000" },
      ],
      cloumns2: [
        { field: "size", title: "部件规格", slot: "size", width: 100 },
        { field: "label", title: "部件名称" },
        { field: "count", title: "数量" },
      ],
      cloumns3: [
        { field: "index", title: "#", width: 45, slot: "indexx" },
        { field: "size", title: "部件规格", slot: "size", width: 100 },
        { field: "position", title: "裁切位置", slot: "position" },
        { field: "result", title: "Result", slot: "result", width: 100 },
      ],
      tableData1: [
        {
          name: "用掉板料",
          val: "1000x1000x1",
        },
        {
          name: "使用总面积",
          val: "100000 \\\ 10%",
        },
      ],
      itemObject: {},
      a4w: 170,
      a4h: 257,
      imageW: 40,
      imageH: 40,
      A4_WIDTH: 592.28,
      A4_HEIGHT: 841.89,
      pdfForm: {},
      banTableData: [],
      banAllTableData: [],
      cutsData: [],
      panelsTableData: [],
      totalTableData: [],
      loading: "",
    };
  },
  mounted() {
    this.$EventBus.$on("pdfHandler", (res) => {
      this.loading = this.$loading({
        lock: true,
        text: "正在导出请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.isShow = true;
      this.pdfForm = res;
      this.$nextTick(() => {
        this.getCuts();
        this.getTotalData();
        // this.panelsTableData = this.itemObject.panels;
        // console.log(this.$refs.pdfDom);
        setTimeout(() => {
          this.handleOutput();
        }, 1000);
      });
    });
  },
  methods: {
    getTotalData() {
      const data = this.itemObject;
      var usedStockPanelsStr = [];
      this.itemObject.usedStockPanels.forEach((res) => {
        usedStockPanelsStr.push(
          res.height +
            "x" +
            res.width +
            " x" +
            res.count +
            (res.label ? " \\ " + res.label : "")
        );
      });
      this.totalTableData = [
        { name: "用掉板料", val: usedStockPanelsStr.join("\n") },
        {
          name: "使用总面积",
          val:
            data.totalUsedArea +
            areaUnitHandle(true) +
            "\\" +
            (data.totalUsedAreaRatio * 100).toFixed(0) +
            "%",
        },
        {
          name: "剩余总面积",
          val:
            data.totalWastedArea +
            areaUnitHandle(true) +
            "\\" +
            (100 - (data.totalUsedAreaRatio * 100).toFixed(0)) +
            "%",
        },
        { name: "裁切总数", val: data.totalNbrCuts },
        { name: "裁切总长", val: data.totalCutLength + areaUnitHandle() },
        {
          name: "锯片厚度",
          val:
            this.$store.getters.configurationData.cutThickness +
            areaUnitHandle(),
        },
        { name: "部件尺寸", val: this.getPanels() + areaUnitHandle() },
        { name: "板料尺寸", val: this.getStockPanels() + areaUnitHandle() },
      ];
    },
    getPanels() {
      var objArr = [];
      this.itemObject.panels.forEach((val) => {
        objArr.push(
          `${val.label} - ${val.height} x ${val.width} x ${val.count}`
        );
      });
      return objArr.join(" \\ ");
    },
    getStockPanels() {
      var objArr = [];
      this.itemObject.usedStockPanels.forEach((val) => {
        objArr.push(
          `${val.label} - ${val.height} x ${val.width} x ${val.count}`
        );
      });
      return objArr.join(" \\ ");
    },
    getBanData(index) {
      var arrStr = [];
      this.itemObject.mosaics.forEach((element) => {
        arrStr.push(handlerBanData(element));
      });
      return JSON.parse(arrStr[index]);
    },
    async handleOutput() {
      const element = document.querySelector(".pdfDom");
      const header = document.querySelector(".pdf-header");
      const footer = document.querySelector(".pdf-footer");

      try {
        await outputPDF({
          element: element,
          footer: footer,
          header: header,
          contentWidth: this.A4_WIDTH,
          filename: this.pdfForm.name + ".pdf",
        }).then((obj) => {
          this.isShow = false;
          if (this.loading) {
            this.loading.close();
          }
        });
      } catch (error) {
        this.isShow = false;
        console.log(error);
        this.$message.error(
          typeof error === "string" ? error : JSON.stringify(error)
        );
      }
    },
    getCuts() {
      var tableData = [];
      if (!this.itemObject) {
        return;
      }
      this.itemObject.mosaics.forEach((res) => {
        for (let index = 0; index < res.cuts.length; index++) {
          const element = res.cuts[index];
          tableData.push(element);
        }
      });
      this.cutsData = tableData;
    },
    canvas2PDF(canvas) {
      let contentWidth = canvas.width;
      let contentHeight = canvas.height;
      //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      let imgWidth = 592.28;
      let imgHeight =
        (592.28 / contentWidth) *
        contentHeight *
        this.itemObject.mosaics.length;

      // 第一个参数： l：横向  p：纵向
      // 第二个参数：测量单位（"pt"，"mm", "cm", "m", "in" or "px"）
      let pdf = new jsPDF("p", "pt");

      pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        0,
        0,
        imgWidth,
        imgHeight
      );

      pdf.save("下料博士.pdf");
      this.isShow = false;
    },
    downloadPDF(page) {
      if (page) {
        html2canvas(page, {
          allowTaint: true,
        })
          .then((canvas) => {
            // 获取canvas画布的宽高
            let contentWidth = canvas.width;
            let contentHeight = canvas.height;
            // 一页pdf显示html页面生成的canvas高度;
            let pageHeight = (this.A4_HEIGHT / contentHeight) * this.A4_WIDTH;
            // 未生成pdf的html页面高度
            let leftHeight = contentHeight;
            // 页面偏移
            let position = 0;
            // html页面生成的canvas在pdf中图片的宽高（本例为：横向a4纸[841.89,592.28]，纵向需调换尺寸）
            let imgWidth = this.A4_WIDTH;
            let imgHeight = (this.A4_WIDTH / contentHeight) * contentWidth;
            let pageData = canvas.toDataURL("image/jpeg", 1.0);
            let PDF = new jsPDF("l", "pt", "a4");
            // 两个高度需要区分: 一个是html页面的实际高度，和生成pdf的页面高度
            // 当内容未超过pdf一页显示的范围，无需分页
            if (leftHeight < pageHeight) {
              PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
            } else {
              while (leftHeight > 0) {
                PDF.addImage(
                  pageData,
                  "JPEG",
                  0,
                  position,
                  imgWidth,
                  imgHeight
                );
                leftHeight -= pageHeight;
                position -= this.A4_HEIGHT;
                // 避免添加空白页
                if (leftHeight > 0) {
                  PDF.addPage();
                }
              }
            }
            PDF.save("下料博士.pdf");
            this.isShow = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tablecss {
  /deep/ .el-table__header-wrapper,
  .el-table__footer-wrapper {
    display: none;
  }
}
.swipe-outside {
  width: 100%;
  height: auto;
  // display: flex;
  position: absolute;
  z-index: -1;
  font-size: 14px;
}
.swipe {
  // width: 1000px;
  // height: 2000px;
  padding: 10px;
  box-sizing: border-box;
}

.line {
  width: 100%;
  height: 1px;
  background: black;
}
.info {
  display: inline-block;
}
.size-box {
  display: flex;
  // margin-top: 40px;
  &:not(:first) {
    // margin-top: 130px !important;
  }
  .table-info {
    width: 30%;
  }
}
.example {
  margin-left: 20px;
  width: 100%;
  height: 100%;
}
</style>
