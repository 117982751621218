import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss'
import '@/styles/adaptive.css'
import XTable from '@/components/XTable'
import store from './store';
import request from '@/tools/api'
import XDialog from '@/components/XDialog'
// import VConsole from 'vconsole/dist/vconsole.min.js'
// let vConsole = new VConsole()

Vue.prototype.$request = request
Vue.component('x-table', XTable)
Vue.component('x-dialog', XDialog)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()

window.KEY_PROJECT_DATA = "PART_SAVE_DATA"
window.KEY_USER_INFO = "USER_INFO"
window.KEY_CONFIGURATION = "CONFIGURATION"
window.RESULT = "RESULT"
window.STATUS = true;
Vue.$httpRequestList = []

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
