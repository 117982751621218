<template>
  <AppMain ></AppMain>
</template>

<script>
import AppMain from "./views/AppMain.vue";

export default {
  name: "App",
  components: {
    AppMain,
  },
};
</script>

<style>
body {
  margin: 0;
}
</style>
