import Vue from 'vue'
import store from '@/store/index';
import { datesFormat } from "@/tools/date";
import { MessageBox } from 'element-ui'

//部件尺寸增空白一列
export function addProjectData(name) {
    var curData = JSON.parse(localStorage.getItem(KEY_PROJECT_DATA))
    var tempData = curData.planSmall
    tempData.push({
        id: 1000 + tempData.length,
        width: "",
        height: "",
        label: "",
        count: "",
        material: "",
        enabled: false,
        isInvalid: false,
        orientation: 0,
    })
    store.dispatch("saveLocalData", { name: name, planSmall: tempData })
}

//板料增空白一列
export function addBanProjectData(name) {
    var curData = JSON.parse(localStorage.getItem(KEY_PROJECT_DATA))
    var tempData = curData.planBig
    tempData.push({
        id: 1000 + tempData.length,
        width: "",
        height: "",
        count: "",
        enabled: false,
        isInvalid: false,
        label: "",
        material: "",
        orientation: 0,
    })
    store.dispatch("saveLocalData", { name: name, planBig: tempData })
}

//移除部件尺寸一列
export function removeProjectData(name, id) {
    var curData = JSON.parse(localStorage.getItem(KEY_PROJECT_DATA))
    var tempData = curData.planSmall
    var index = tempData.findIndex(val => val.id === id);
    if (index > -1) {
        tempData.splice(index, 1)
    }
    store.dispatch("saveLocalData", { name: name, planSmall: tempData })
}

//替换本地保存的列表数据
export function replaceTableData({ ...obj }) {
    store.dispatch("saveLocalData", { name: obj.name, planSmall: obj.planSmall, planBig: obj.planBig })
}

//移除板料一列
export function removeBanProjectData(name, id) {
    var curData = JSON.parse(localStorage.getItem(KEY_PROJECT_DATA))
    var tempData = curData.planBig
    var index = tempData.findIndex(val => val.id === id);
    if (index > -1) {
        tempData.splice(index, 1)
    }
    store.dispatch("saveLocalData", { name: name, planBig: tempData })
}

//生成空列表
export function blankProjectData(projectName) {
    var tempData = getClearData()
    var params = {
        "name": projectName ? projectName : "" + datesFormat(Number(new Date()) / 1000, "YYYY-MM-DD"),
        "planSmall": JSON.parse(JSON.stringify(tempData)),
        "planBig": JSON.parse(JSON.stringify(tempData)),
    }
    store.dispatch("savePartObjectData", JSON.stringify(params))
    return params
}

export function getClearData() {
    var tempData = []
    for (let index = 0; index < 4; index++) {
        var id1 = 1000 + index
        tempData.push({
            id: id1,
            width: "",
            height: "",
            count: "",
            enabled: false,
            isInvalid: false,
            label: "",
            material: "",
            orientation: 0,
        })
    }
    return tempData
}

// 中断请求方法
export function clearHttpRequestingList() {
    if (Vue.$httpRequestList.length > 0) {
        Vue.$httpRequestList.forEach(item => {
            item('取消请求')
        })
        Vue.$httpRequestList = []
    }
}
//单位处理
export function areaUnitHandle(isIn) {
    var units = ""
    if (store.getters.configurationData.units === 2) {
        units = " mm"
    } else if (store.getters.configurationData.units === 3) {
        units = " cm"
    } else if (store.getters.configurationData.units === 4) {
        if (isIn) {
            units = " in²"
        } else {
            units = '"'
        }
    }
    return units
}
// 处理板料数据
export function handlerBanData(element) {
    var count = element.count ? element.count : 1
    var dataArr = [
        {
            name: "板料尺寸",
            val: element.tiles[0].height + "x" + element.tiles[0].width,
        },
        { name: "材料", val: element.material },
        { name: "数量", val: count },
        {
            name: "使用面积",
            val:
                element.usedArea + areaUnitHandle(true) +
                " \\ " +
                (element.usedAreaRatio * 100).toFixed(0) +
                " %",
        },
        {
            name: "剩余面积",
            val:
                element.wastedArea + areaUnitHandle(true) +
                " \\ " +
                (100 - (element.usedAreaRatio * 100).toFixed(0)) +
                " %",
        },
        { name: "裁切次数", val: element.cuts.length },
        { name: "裁切长度", val: element.cutLength + areaUnitHandle() },
        { name: "部件尺寸", val: element.nbrFinalPanels + areaUnitHandle() },
        { name: "余料数量", val: element.nbrWastedPanels },
    ]
    if (!element.material) {
        dataArr.splice(1, 1)
    }
    return JSON.stringify(dataArr);
}

export function isLogin() {
    if (store.getters.userInfo && store.getters.userInfo.token) {
        return true
    } else {
        return false
    }
}
export function goLogin() {
    var promise = new Promise((resolve, reject) => {
        MessageBox.confirm('您还没有登录，请先登录！', '请先登录', {
            confirmButtonText: '去登录',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            resolve(true)
            store.dispatch("isReLogin", true)
        }).catch((err) => {
            resolve(false)
            store.dispatch("isReLogin", false)
        })
    })
    return promise

}

export function uniqueFunc(...args) {
    const paramsLength = arguments.length;
    if (paramsLength == 1) {
        return [...new Set(arr)];
    } else {
        const res = new Map();
        const flag = arguments[1];
        return arguments[0].filter(item => !res.has(item[flag]) && res.set(item[flag], item));
    }
}