<template>
  <div>
    <div class="nav_content">
      <div class="nav_left">
        <img
          v-if="!$store.state.isPhone"
          src="@/assets/images/logo.png"
          alt=""
        />
        <div v-if="!$store.state.isPhone" class="title" style="font-size: 18px">
          下料博士
        </div>
        <div v-if="!$store.state.isPhone" class="title">
          {{ $store.getters.projectName }}
        </div>
      </div>
      <div class="nav_right">
        <el-button
          v-if="!playStatus"
          @click="playClick"
          type="success"
          size="small"
          style="margin-right: 10px"
        >
          <span class="glyphicon glyphicon-play glyphicon-cust"></span>
          计算</el-button
        >
        <el-button
          v-else-if="playStatus"
          @click="stopClick"
          type="info"
          size="small"
          style="margin-right: 10px"
          ><span class="glyphicon glyphicon-stop glyphicon-cust"></span>
          确认</el-button
        >

        <el-dropdown
          style="margin-right: 10px"
          split-button
          type="info"
          size="small"
          @click="saveClick"
          @command="otherSaveClick"
          class="mydropdown"
        >
          <span class="glyphicon glyphicon-save glyphicon-cust"></span> 保存
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>另存为...</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button type="info" size="small" @click="settingClick"
          ><span
            class="glyphicon glyphicon-cog glyphicon-cust"
            style="transform: scale(1.8)"
          ></span
        ></el-button>

        <el-button
          v-if="!userInfo || !userInfo.token"
          type="info"
          size="small"
          @click="loginClick"
          >登录</el-button
        >
        <el-dropdown v-else trigger="click" @command="loginOut">
          <el-button type="text" style="padding: 0px 10px !important">
            <img class="header" src="@/assets/images/head.png" alt="" />
            <i
              class="el-icon-caret-bottom"
              style="position: relative; top: 0px"
            ></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <div style="padding: 0px 10px !important">
              {{ userInfo && userInfo.name }}
            </div>
            <el-dropdown-item
              ><div style="width: 100%; text-align: center">
                退出登录
              </div></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown type="primary" trigger="click" @command="pdfClick">
          <el-button type="text" class="options-btn"
            ><img
              class="glyphicon-cust"
              style="height: 25px; position: relative; top: 0px"
              src="@/assets/images/more.png"
              alt=""
          /></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="clear"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file"
                style="
                  margin-bottom: -6px;
                  margin-right: 6px;
                  margin-left: -3px;
                "
              >
                <path
                  d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"
                ></path>
                <polyline points="13 2 13 9 20 9"></polyline>
              </svg>
              <span style="color: #333">新建项目</span>
            </el-dropdown-item>
            <el-dropdown-item command="upload"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-upload"
                style="
                  margin-bottom: -6px;
                  margin-right: 6px;
                  margin-left: -3px;
                "
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="17 8 12 3 7 8"></polyline>
                <line x1="12" y1="3" x2="12" y2="15"></line>
              </svg>
              <span style="color: #333">项目列表</span></el-dropdown-item
            >
            <el-divider></el-divider>
            <el-dropdown-item command="pdf"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text"
                style="
                  margin-bottom: -6px;
                  margin-right: 6px;
                  margin-left: -3px;
                "
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                ></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
              <span style="color: #333">输出为PDF</span></el-dropdown-item
            >
            <el-dropdown-item command="dxf">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text"
                style="
                  margin-bottom: -6px;
                  margin-right: 6px;
                  margin-left: -3px;
                "
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                ></path>
                <!-- <polyline points="14 2 14 8 20 8"></polyline> -->
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <!-- <polyline points="10 9 9 9 8 9"></polyline> -->
              </svg>
              <span style="color: #333">输出为DXF</span></el-dropdown-item
            >
            <el-dropdown-item command="jpeg"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-image"
                style="
                  margin-bottom: -6px;
                  margin-right: 6px;
                  margin-left: -3px;
                "
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <circle cx="8.5" cy="8.5" r="1.5"></circle>
                <polyline points="21 15 16 10 5 21"></polyline>
              </svg>
              <span style="color: #333">输出为图片</span></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <x-dialog
      title="登录"
      width="345px"
      :dialogVisible="dialogLoginVisible"
      @confirm="loginHandle"
      @handleClose="cancelClick"
      class="mydialogcss"
    >
      <el-form label-width="65px">
        <el-form-item label="手机号">
          <el-input
            class="myinput"
            v-model="phone"
            placeholder="请输入手机号"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-row :gutter="7">
            <el-col :span="14" style="padding: 0 !important">
              <el-input
                class="myinput"
                v-model="code"
                placeholder="请输入验证码"
              ></el-input
            ></el-col>
            <el-col :span="8"
              ><el-button
                style="position: relative; top: -1px; width: 92px"
                size="mini"
                @click="sendCode"
                :disabled="loginEnabled"
                >{{ sendCodeText }}</el-button
              ></el-col
            >
          </el-row>
        </el-form-item>
      </el-form>
    </x-dialog>
    <x-dialog
      title="输出为PDF档"
      :dialogVisible="moreDialogVisible"
      @confirm="confirm"
      @handleClose="moreDialogVisible = false"
      width="370px"
    >
      <el-form label-width="105px" v-model="pdfForm">
        <el-form-item label="档案名称">
          <el-input v-model="pdfForm.name">
            <template slot="append">.pdf</template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="字体大小">
          <el-input v-model="pdfForm.fontSize"> </el-input>
        </el-form-item> -->
        <el-form-item label="Header text">
          <el-input v-model="pdfForm.headerText"> </el-input>
        </el-form-item>
        <!-- <el-form-item label="Additional text">
          <el-input v-model="pdfForm.additionalText"> </el-input>
        </el-form-item> -->
        <!-- <el-form-item label="显示概要">
          <el-switch v-model="pdfForm.summary"> </el-switch>
        </el-form-item>
        <el-form-item label="显示裁切圆">
          <el-switch v-model="pdfForm.round"> </el-switch>
        </el-form-item>
        <el-form-item label="显示日期">
          <el-switch v-model="pdfForm.date"> </el-switch>
        </el-form-item> -->
      </el-form>
    </x-dialog>

    <x-dialog
      title="输出为DXF格式"
      :dialogVisible="dxfDialogVisible"
      @confirm="dxfConfirm"
      @handleClose="dxfDialogVisible = false"
      width="370px"
    >
      <el-form label-width="50px">
        <el-form-item label="名称">
          <el-input v-model="dxfName">
            <template slot="append">.dxf</template>
          </el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
    <x-dialog
      title="输出为图片"
      :dialogVisible="jpegDialogVisible"
      @confirm="jpegConfirm"
      @handleClose="jpegDialogVisible = false"
      width="370px"
    >
      <el-form label-width="50px">
        <el-form-item label="名称">
          <el-input v-model="jpegName">
            <template slot="append">.jpeg</template>
          </el-input>
        </el-form-item>
      </el-form>
    </x-dialog>

    <x-dialog
      title="设置"
      width="550px"
      :dialogVisible="optionsDialogVisible"
      @confirm="settingHandle"
      @handleClose="settingCancelClick"
      class="setclass"
    >
      <el-form label-width="300px" label-position="left" v-model="settingForm">
        <el-form-item label="优化优先级">
          <el-select
            v-model="settingForm.optimizationPriority"
            style="width: 202px"
          >
            <el-option label="最少余料面积" :value="0"></el-option>
            <el-option label="最小切割次数" :value="1"></el-option>
            <el-option label="优先使用最小尺寸板料" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="切割方向">
          <el-select
            v-model="settingForm.cutOrientationPreference"
            style="width: 202px"
          >
            <el-option label="最优" :value="0"></el-option>
            <el-option label="优先选择横向切割" :value="1"></el-option>
            <el-option label="优先选择纵向切割" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位">
          <el-select v-model="settingForm.units" style="width: 202px">
            <el-option label="公英制通用(16.5)" :value="1"></el-option>
            <el-option label="公厘(16.5mm)" :value="2"></el-option>
            <el-option label="公分(16.5cm)" :value="3"></el-option>
            <el-option label='英寸(16.5")' :value="4"></el-option>
            <!-- <el-option label="十进制英尺英寸" :value="5"></el-option>
            <el-option label="分数制英尺与英寸" :value="6"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="尺寸顺序">
          <el-select v-model="settingForm.isWidthFirst" style="width: 202px">
            <el-option label="长*宽" :value="false"></el-option>
            <el-option label="宽*长" :value="true"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小数位数">
          <el-input
            v-model="settingForm.decimalPlaces"
            class="normal-input"
            placeholder="小数位数"
            type="number"
            @input="
              settingForm.decimalPlaces =
                settingForm.decimalPlaces > 10
                  ? (settingForm.decimalPlaces = 10)
                  : settingForm.decimalPlaces < 0
                  ? (settingForm.decimalPlaces = 0)
                  : settingForm.decimalPlaces
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="最小裁切尺寸">
          <el-input
            v-model="settingForm.minTrimDimension"
            class="normal-input"
            placeholder="最小裁切尺寸"
          ></el-input>
        </el-form-item>
        <el-form-item label="堆叠具有相同切割布局的板料">
          <el-select
            v-model="settingForm.stackEqualMosaicLayout"
            style="width: 202px"
          >
            <el-option label="自动" :value="0"></el-option>
            <el-option label="总是" :value="1"></el-option>
            <el-option label="永不" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </x-dialog>
    <x-dialog
      title="存储专案"
      width="350px"
      :dialogVisible="saveDialogVisible"
      @confirm="saveHandle"
      @handleClose="saveCancelClick"
    >
      <el-form label-width="60px" label-position="left">
        <el-form-item label="另存为">
          <el-input
            v-model="projectName"
            class="normal-input"
            placeholder="请输入项目名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>

    <x-dialog
      title="项目列表"
      :width="$store.state.isPhone ? '100%' : '700px'"
      :isFooter="false"
      :dialogVisible="upDialogVisible"
      @handleClose="upCancelClick"
    >
      <x-table
        :dataSource="tableData"
        :cloumns="cloumns"
        :total="total"
        @sizeChange="sizeChange"
        @currentChange="currentChange"
        :currentPage="currentPage"
        class="mytablecss"
      >
        <template #control="{ scope }">
          <el-button
            type="text"
            size="small"
            icon="el-icon-download"
            class="custom-btn"
            @click="importClick(scope.row)"
          ></el-button>
          <el-button
            type="text"
            size="small"
            icon="el-icon-edit"
            class="custom-btn"
            @click="editClick(scope.row)"
          ></el-button>
          <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            class="custom-btn"
            @click="delClick(scope.row)"
          ></el-button>
        </template>
        <template #createTime="{ scope }">
          <span>{{ datesFormatTime(scope.row.createTime) }}</span>
        </template>
        <template #updateTime="{ scope }">
          <span>{{ datesFormatTime(scope.row.updateTime) }}</span>
        </template>
      </x-table>
    </x-dialog>
  </div>
</template>

<script>
import { setToken, removeToken } from "@/tools/auth";
import { datesFormat } from "@/tools/date";
import {
  clearHttpRequestingList,
  blankProjectData,
  isLogin,
  goLogin,
} from "@/tools/utils";

export default {
  data() {
    return {
      dialogVisible: false,
      moreDialogVisible: false,
      dialogLoginVisible: false,
      saveDialogVisible: false,
      upDialogVisible: false,
      phone: "",
      code: "",
      pdfForm: {
        name: "" + datesFormat(Number(new Date()) / 1000, "YYYY-MM-DD"),
        fontSize: "14px",
        headerText: "下料博士",
        summary: true,
        round: true,
        date: true,
      },
      optionsDialogVisible: false,
      projectName: "" + datesFormat(Number(new Date()) / 1000, "YYYY-MM-DD"),
      tableData: [],
      cloumns: [
        {
          field: "name",
          title: "名称",
          tooltip: true,
        },
        {
          field: "createTime",
          title: "创建时间",
          slot: "createTime",
        },
        {
          field: "updateTime",
          title: "修改时间",
          slot: "updateTime",
        },
        {
          field: "control",
          slot: "control",
          align: "center",
        },
      ],
      settingForm: {
        optimizationPriority: 0,
        cutOrientationPreference: 0,
        units: 1,
        isWidthFirst: false,
        decimalPlaces: 2,
        minTrimDimension: 0,
        stackEqualMosaicLayout: 1,
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      currentPage: 1,
      dxfDialogVisible: false,
      dxfName: "",
      jpegDialogVisible: false,
      jpegName: "",
      loginEnabled: false,
      sendCodeText: "发送验证码",
    };
  },
  computed: {
    isRelogin() {
      return this.$store.state.isReLoginStatus;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    playStatus() {
      return this.$store.getters.playStatus;
    },
    datesFormatTime() {
      return (val) => {
        return datesFormat(val / 1000, "YYYY-MM-DD hh:mm:ss");
      };
    },
    getProjectName() {
      return this.$store.getters.projectName;
    },
  },
  watch: {
    isRelogin: {
      handler(nval, oval) {
        this.dialogLoginVisible = nval;
      },
      immediate: true,
    },
    getProjectName(val) {
      this.projectName = val;
      this.pdfForm.name = val;
      this.dxfName = val;
      this.jpegName = val;
    },
  },
  mounted() {
    // this.settingHandle();
    window.onresize = () => {
      return (() => {})();
    };
  },
  methods: {
    loginOut() {
      this.$request.logout().then((res) => {
        if (res.code === "200") {
          removeToken();
          this.$store.dispatch("userInfo", 0);
          window.localStorage.clear();
        }
      });
    },
    sendCode() {
      var regExp = new RegExp("^1[345678]\\d{9}$");
      if (!regExp.test(this.phone)) {
        this.$message.error("请输入正确的手机号码！");
        return;
      }
      this.loginEnabled = true;
      this.$request
        .sendCode({ codeSymbol: 2, mobile: this.phone })
        .then((res) => {
          if (res.code === "200") {
            this.$message.success("已发送！");
            var count = 60;
            var timer = setInterval(() => {
              count--;
              this.sendCodeText = `${count}s`;
              if (count === 0) {
                clearInterval(timer);
                this.loginEnabled = false;
                this.sendCodeText = `发送验证码`;
              }
            }, 1000);
          } else {
            this.loginEnabled = false;
          }
        });
    },
    loginHandle() {
      this.$request
        .login({
          account: this.phone,
          code: this.code,
          type: 1,
        })
        .then((res) => {
          if (res.code == "200") {
            setToken(res.data.encrypt.token);
            this.$store.dispatch("userInfo", JSON.stringify(res.data.encrypt));
            this.dialogLoginVisible = false;
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    cancelClick() {
      this.dialogLoginVisible = false;
      window.STATUS = true;
      this.$store.dispatch("isReLogin", false);
    },
    loginClick() {
      this.dialogLoginVisible = true;
    },
    upCancelClick() {
      this.upDialogVisible = false;
    },

    pdfClick(index) {
      if (!isLogin()) {
        goLogin();
        return;
      }
      if (index == "pdf") {
        this.moreDialogVisible = true;
      } else if (index === "upload") {
        this.upDialogVisible = true;
        this.getObjectList();
      } else if (index === "dxf") {
        this.dxfDialogVisible = true;
      } else if (index === "clear") {
        this.$store.dispatch("getPartObjectData").then((res) => {
          if (!res.id) {
            this.saveObjectData();
          } else {
            this.$request.getObjectDetail({ id: res.id }).then((res2) => {
              if (this.isSame(res, res2)) {
                blankProjectData();
                this.$EventBus.$emit("clearSvgDom");
              } else {
                this.saveObjectData();
              }
            });
          }
        });
      } else if (index === "jpeg") {
        this.jpegDialogVisible = true;
      }
    },
    isSame(curData, firstData) {
      var isSameBool = false;
      let planBig = JSON.parse(firstData.data.encrypt.planBig);
      let planSmall = JSON.parse(firstData.data.encrypt.planSmall);
      var oldBig = this.sameParamsHandle(planBig);
      var oldSmall = this.sameParamsHandle(planSmall);
      var curBig = this.sameParamsHandle(curData.planBig);
      var curSmall = this.sameParamsHandle(curData.planSmall);
      if (
        JSON.stringify(oldBig) === JSON.stringify(curBig) &&
        JSON.stringify(oldSmall) === JSON.stringify(curSmall)
      ) {
        isSameBool = true;
      }
      return isSameBool;
    },
    sameParamsHandle(valArr) {
      var arr = [];
      valArr.forEach((val) => {
        arr.push({
          count: val.count,
          enabled: val.enabled,
          height: val.height,
          label: val.label,
          material: val.material,
          width: val.width,
        });
      });
      return arr;
    },
    //提示保存数据
    saveObjectData() {
      this.$confirm("您还没有保存改数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$prompt("项目名称", "新建项目", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              if (value && value != " ") {
                blankProjectData(value);
                this.$EventBus.$emit("clearSvgDom");
              } else {
                this.$message.error("不能为空！");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    jpegConfirm() {
      this.$EventBus.$emit("jpegHandler");
      this.jpegDialogVisible = false;
    },
    exportDxf() {
      if (this.dxfName.indexOf(" ") > -1) {
        this.dxfName = this.dxfName.replace(/ /g, "");
      }
      this.$request
        .outDxf({
          fileName: this.dxfName,
          planRootBean: this.$store.getters.result,
        })
        .then((res) => {
          if (res.code === "200") {
            this.dxfDialogVisible = false;
            const link = document.createElement("a");
            const event = new MouseEvent("click");
            link.download = this.dxfName + ".jpeg";
            link.href = res.data.encrypt.path;
            link.dispatchEvent(event);
          }
        });
    },
    dxfConfirm() {
      this.exportDxf();
    },
    getObjectList() {
      this.$request
        .queryList({ pageNo: this.pageNo, pageSize: this.pageSize })
        .then((res) => {
          if (res.code === "200") {
            this.tableData = res.data.encrypt.records;
            this.total = res.data.encrypt.total;
          } else {
            this.upDialogVisible = false;
          }
        });
    },
    currentChange(val) {
      this.pageNo = val;
      this.currentPage = val;
      this.getObjectList();
    },
    sizeChange(val) {
      this.pageSize = val;
      this.getObjectList();
    },
    confirm() {
      this.$EventBus.$emit("pdfHandler", this.pdfForm);
      this.moreDialogVisible = false;
    },
    playClick() {
      if (!isLogin()) {
        goLogin();
        return;
      }
      this.$EventBus.$emit("play", true);
    },
    stopClick() {
      clearHttpRequestingList();
      this.$EventBus.$emit("play", false);
    },
    saveClick() {
      this.$store.dispatch("saveProjectData", { isOther: false }).then(() => {
        this.saveDialogVisible = false;
        this.$message.success("保存成功！");
      });
    },
    otherSaveClick() {
      this.saveDialogVisible = true;
    },
    settingHandle() {
      var params = this.$store.state.configurationData;
      for (const key in this.settingForm) {
        const element = this.settingForm[key];
        params[key] = element;
      }
      this.$store.dispatch("configuration", JSON.stringify(params));
      this.optionsDialogVisible = false;
    },
    settingClick() {
      this.optionsDialogVisible = true;
      var params = this.$store.getters.configurationData;
      for (const key in this.settingForm) {
        if (params[key] != null) {
          const element = params[key];
          this.settingForm[key] = element;
        }
      }
    },
    settingCancelClick() {
      this.optionsDialogVisible = false;
    },
    saveHandle() {
      this.$store
        .dispatch("saveProjectData", { name: this.projectName, isOther: true })
        .then(() => {
          this.saveDialogVisible = false;
          this.$message.success("保存成功！");
        });
    },
    saveCancelClick() {
      this.saveDialogVisible = false;
    },
    //载入数据
    importClick(row) {
      const loading = this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
      });
      this.$request.getObjectDetail({ id: row.id }).then((res) => {
        loading.close();
        if (res.code === "200") {
          this.$store.dispatch("saveLocalData", {
            name: res.data.encrypt.name,
            planBig: JSON.parse(res.data.encrypt.planBig),
            planSmall: JSON.parse(res.data.encrypt.planSmall),
            id: row.id,
          });
          this.upDialogVisible = false;
        }
      });
    },
    editClick(row) {
      this.$prompt("请输入名称", "修改", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.name,
      })
        .then(({ value }) => {
          if (value && value != " ") {
            const loading = this.$loading({
              lock: true,
              text: "",
              spinner: "el-icon-loading",
            });
            this.$request
              .saveQueryList({ id: row.id, name: value })
              .then((res) => {
                loading.close();
                if (res.code === "200") {
                  this.$message.success("修改成功！");
                  this.getObjectList();
                  this.$store.dispatch("getPartObjectData").then((res) => {
                    if (res.id === row.id) {
                      this.$store.dispatch("saveLocalData", {
                        name: value,
                        planBig: res.planBig,
                        planSmall: res.planSmall,
                        id: row.id,
                      });
                    }
                  });
                } else {
                }
              });
          } else {
            this.$message.error("不能为空！");
          }
        })
        .catch(() => {});
    },
    delClick(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request.delObject({ id: row.id }).then((res) => {
            if (res.code === "200") {
              this.$message.success("删除成功！");
              this.getObjectList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_content {
  width: 100%;
  height: 40px;
  background: $color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  color: #fff;
}
.nav_left {
  display: flex;
  img {
    width: 25px;
    image-rendering: -webkit-optimize-contrast; /* Safari 和 Chrome */
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: pixelated;
  }
  .title {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}
.nav_right {
  display: flex;
  align-items: center !important;
}
.normal-input {
  /deep/ .el-input__inner {
    height: 28px !important;
    line-height: 28px !important;
    // padding: 0 5px !important;
    width: 202px;
  }
}
.loginInput {
  /deep/ .el-input__inner {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 5px !important;
    max-width: 240px;
  }
}
.options-btn {
  font-size: 17px;
  padding: 0px 10px !important;
  /deep/ .el-button {
    // padding: 0px 20px !important;
  }
}

.header {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 5px;
  background: $them-color;
  position: relative;
  top: 0px;
}
.custom-btn {
  // height: 0px !important;
  position: relative;
  top: -4px;
  transform: scale(1.1);
}
.mytablecss {
  /deep/
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: rgb(200, 230, 201) !important;
  }
}
.setclass {
  /deep/ .el-form-item__label {
    font-size: 16px;
  }
  /deep/ .el-form-item__content {
    font-size: 16px;
  }
}
</style>
<style lang="scss">
.el-button--small {
  font-size: 13px !important;
}
</style>
