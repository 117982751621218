import Vue from 'vue'
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import AES from '@/tools/AES'
import store from '@/store';
import { getToken, removeToken } from '@/tools/auth'
import { goLogin } from '@/tools/utils'

// create an axios instance https://www.volcano-play.com/ http://13.214.57.119:8800/  http://192.168.3.6:8801
var baseURL = "https://api-web.cutlist.work";//"http://192.168.3.6:8801"
const service = axios.create({
  baseURL: baseURL,
  timeout: 60000
})

service.interceptors.request.use(
  config => {
    config.cancelToken = new axios.CancelToken(function executor(cancel) {
      Vue.$httpRequestList.push(cancel)
    })
    config.url = config.baseURL + config.url
    config.data = { sign: AES.encrypt(JSON.stringify(config.data)) }
    if (getToken()) {
      config.headers['t'] = getToken()
    }
    config.headers["Content-Type"] = "application/json;charset=utf-8"

    config.headers['source'] = "app"
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code != '200') {
      if (res.code === '402' && window.STATUS) {
        removeToken();
        store.dispatch("userInfo", 0);
        window.localStorage.clear();
        window.STATUS = false
        goLogin().then(isConfirm => {
          if (!isConfirm) {
            window.STATUS = true
          }
        })
      } else {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      // return Promise.reject(new Error(res.message || 'Error'))
      return res
    } else {

      if (res.data) {
        if (response.config.url === baseURL + "/cut/plan/run") {
          res.data.encrypt = AES.decrypt(res.data.encrypt)
        } else {
          if (response.config.url === baseURL + "/cut/plan/result") {
            //保存计算结果
            store.dispatch("setResult", res.data.encrypt)
          }
          res.data.encrypt = JSON.parse(AES.decrypt(res.data.encrypt))
        }
      }
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
