import Vue from 'vue'
import Vuex from 'vuex'
import request from '@/tools/api'
import { datesFormat } from "@/tools/date";
import { blankProjectData } from "@/tools/utils"
import AES from '@/tools/AES'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isReLoginStatus: false,
        userInfo: JSON.parse(localStorage.getItem(window.KEY_USER_INFO)),
        projectName: "",
        cutData: {},
        leftData: "",
        configurationData: JSON.parse(localStorage.getItem(window.KEY_CONFIGURATION)),
        result: "",
        playStatus: false,
        widthFirstStatus: true,
        isPhone: document.documentElement.clientWidth < 750
    },
    mutations: {
        IS_RE_LOGIN(state, status) {
            state.isReLoginStatus = status
        },
        KEY_USER_INFO(state, data) {
            localStorage.setItem(window.KEY_USER_INFO, data)
            state.userInfo = JSON.parse(data)
        },
        CUT_DATA(state, data) {
            state.cutData = data
        },
        PART_SAVE_DATA(state, data) {
            state.leftData = data
        },
        PROJECT_NAME(state, name) {
            state.projectName = name
        },
        CONFIGURATION(state, data) {
            localStorage.setItem(window.KEY_CONFIGURATION, data)
            state.configurationData = data
        },
        RESULT(state, data) {
            localStorage.setItem(window.RESULT, data)
            state.result = data
        },
        PLAY_STATUS(state, status) {
            state.playStatus = status
        },
        WIDTH_FIRST_StATUS(state, status) {
            state.widthFirstStatus = status
        },
    },
    actions: {
        isReLogin({ commit }, status) {
            commit('IS_RE_LOGIN', status)
        },
        userInfo({ commit }, data) {
            commit('KEY_USER_INFO', data)
        },
        cutData({ commit }, data) {
            commit('CUT_DATA', data)
        },
        projectName({ commit }, name) {
            commit('PROJECT_NAME', name)
        },
        configuration({ commit }, data) {
            commit('CONFIGURATION', data)
        },
        setResult({ commit }, data) {
            commit('RESULT', data)
        },
        playStatus({ commit }, status) {
            commit('PLAY_STATUS', status)
        },
        chanageWidthFirstStatus({ commit }, status) {
            commit('WIDTH_FIRST_StATUS', status)
        },
        // 获取数据
        getPartObjectData({ commit }, data) {
            return new Promise((resolve, reject) => {
                var saveData = this.getters.leftData
                if (saveData) {
                    commit('PART_SAVE_DATA', JSON.stringify(saveData))
                    resolve(saveData)
                } else {
                    const params = blankProjectData()
                    commit('PART_SAVE_DATA', JSON.stringify(params))
                    resolve(params)
                }
            })
        },
        // 保存数据到本地（对外）
        saveLocalData({ commit }, { ...obj }) {
            var curData = this.getters.leftData ? this.getters.leftData : {}
            var params = {
                "name": obj.name ? obj.name : curData.name ? curData.name : "Project " + datesFormat(Number(new Date()) / 1000, "YYYY-MM-DD"),
                "planBig": obj.planBig ? obj.planBig : curData.planBig,
                "planSmall": obj.planSmall ? obj.planSmall : curData.planSmall,
            }
            if (curData.id || obj.id) {
                params.id = obj.id ? obj.id : curData.id
            }

            this.dispatch("savePartObjectData", JSON.stringify(params))
            return params
        },
        // 保存数据到本地(内部) 最终都到这里保存
        savePartObjectData({ commit }, data) {
            localStorage.setItem(window.KEY_PROJECT_DATA, data)
            commit('PART_SAVE_DATA', data)
        },
        // 存档到数据库
        saveProjectData({ commit }, { ...obj }) {
            return new Promise((resolve, reject) => {
                var projectData = this.getters.leftData
                var params = {
                    "name": obj.isOther ? obj.name : projectData.name,
                    "planBig": JSON.stringify(projectData.planBig),
                    "planSmall": JSON.stringify(projectData.planSmall),
                }
                if (projectData.id && !obj.isOther) {
                    params.id = projectData.id
                }
                request.saveQueryList(params).then(res => {
                    if (res.code === "200") {
                        this.dispatch("saveLocalData", { name: params.name, id: res.data.encrypt.id })
                        resolve()
                    }
                })
            })
        }
    },
    getters: {
        configurationData: (state) => {
            state.configurationData = localStorage.getItem(window.KEY_CONFIGURATION) ? JSON.parse(localStorage.getItem(window.KEY_CONFIGURATION)) : {
                cutThickness: "0",
                isTileLabelVisible: false,
                useSingleStockUnit: false,
                isMaterialEnabled: false,
                hasEdgeBanding: false,
                considerOrientation: false,
                optimizationPriority: 0,
                cutOrientationPreference: 0,
                units: 1,
                isWidthFirst: false,
                decimalPlaces: 2,
                minTrimDimension: 0,
                stackEqualMosaicLayout: 1,
            };
            return state.configurationData;
        },
        leftData: (state) => {
            state.leftData = JSON.parse(localStorage.getItem(window.KEY_PROJECT_DATA))
            return state.leftData
        },
        userInfo: (state) => {
            state.userInfo = JSON.parse(localStorage.getItem(window.KEY_USER_INFO))
            return state.userInfo
        },
        result: (state) => {
            if (localStorage.getItem(window.RESULT)) {
                state.result = JSON.parse(AES.decrypt(localStorage.getItem(window.RESULT)))
            }
            return state.result
        },
        playStatus: (state) => {
            return state.playStatus
        },
        widthFirstStatus: (state) => {
            state.widthFirstStatus = state.configurationData.isWidthFirst
            return state.widthFirstStatus
        },
        projectName: (state) => {
            var curData = state.leftData
            if (curData) {
                if (typeof state.leftData === "string") {
                    curData = JSON.parse(state.leftData)
                }
                state.projectName = curData.name
            }
            return state.projectName
        }
    }
})

export default store