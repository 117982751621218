<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :width="width"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <slot></slot>
      <span v-if="isFooter" slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="confirm"
          :loading="vloading"
          style="background-color: #337ab7; border-color: #337ab7"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "提示",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    isFooter: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: "560px",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialogVisible: {
      handler(newVal, oldVal) {
        this.visible = newVal;
      },
      immediate: true,
      deep: true,
    },
    loading: {
      handler(newVal, oldVal) {
        this.vloading = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      vloading: false,
    };
  },
  methods: {
    handleClose() {
      this.visible = false;
      this.$emit("handleClose");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style>
.mydialogcss {
  max-width: 550px !important;
}
</style>
