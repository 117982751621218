import request from '@/tools/request'
export default {
    login(data) {
        return request({
            url: '/user/login',
            method: 'post',
            data
        })
    },
    logout(data) {
        return request({
            url: '/user/logout',
            method: 'post',
            data
        })
    },
    sendCode(data) {
        return request({
            url: '/user/sendCode',
            method: 'post',
            data
        })
    },
    compute(data) {
        return request({
            url: '/cut/plan/run',
            method: 'post',
            data
        })
    },
    //计算结果获取
    getResult(data) {
        return request({
            url: '/cut/plan/result',
            method: 'post',
            data
        })
    },
    //列表数据 
    queryList(data) {
        return request({
            url: '/data/queryList',
            method: 'post',
            data
        })
    },
    //保存修改
    saveQueryList(data) {
        return request({
            url: '/data/save',
            method: 'post',
            data
        })
    },
    //获取专案详情
    getObjectDetail(data) {
        return request({
            url: '/data/detail',
            method: 'post',
            data
        })
    },
    //存储删除 
    delObject(data) {
        return request({
            url: '/data/del',
            method: 'post',
            data
        })
    },
    //导出dxf格式 
    outDxf(data) {
        return request({
            url: '/cut/plan/file',
            method: 'post',
            data
        })
    },
}


